import { useState, useEffect, useRef, useContext } from 'react'
import _ from 'lodash'
import useSound from 'use-sound';

import helper from './helper'
import { GlobalContext } from './useContextReducer'

import { numberConversion, playSound, greenYellowRed, getTimeTwoDigitFormat, getTimeFromLong, getTimeFromLongInHrs } from '../utils/jshelper'
import { getColorFunction, getColorOnKey } from '../utils/gradient'

import int5change3SFX from '../sounds/state-change_confirm-up.wav'
import haltedSFX from '../sounds/decay-5-3.mp3'

// sound
// let playInt5SFX = new Audio(int5change3SFX)
// playInt5SFX.volume = 0.2
// let playHalted = new Audio(haltedSFX)
// playHalted.volume = 0.2


export default function useScreener(screenerSettings) {
    // global context
    const [globalState, globalStateDispatch] = useContext(GlobalContext)
    const [isFirstTimeDisplay, setIsFirstTimeDisplay] = useState(true)
    // const [settings, setSettings] = useState(screenerSettings.settings)
    const sound = globalState.sound
    // console.log(screenerSettings)
    // let initialScreenerData = await fetchScreener()
    const [screenerData, setScreenerData] = useState([])
    const [socketScreenerData, setSocketScreenerData] = useState([])
    let prevScreenerData = useRef([])

    // sound
    const [playInt5SFX] = useSound(int5change3SFX, { volume: 0.4 });
    const [playHalted] = useSound(haltedSFX, { volume: 0.4 });

    /**
     * use socket connection to fetch screener info
     */
    useEffect(() => {
        const socket = helper.getSocketConn()
        socket.on(screenerSettings.socketChannel, msg => {
            // console.log(msg);
            // console.log(`${Date.now()} got the data on socket`)
            setSocketScreenerData(msg.message)
        });
        
        // return
    }, [])


    // /**
    //  * Use this for the first time to get scrrener info using API (to show the result as soon as page loads)
    //  */
    // useEffect(() => {
    //     const getScreener = async () => {
    //         const socketScreenInfo = await fetchScreener()
    //         setSocketScreenerData(socketScreenInfo)
    //     }
    //     getScreener()
    // }, [])


    /**
     * Update the table, mostly when user has updated the filter for the table
     */
    useEffect(() => {
        setSocketScreenerData([])
        prevScreenerData.current = []
        setIsFirstTimeDisplay(true)
        const getScreener = async () => {
            const socketScreenInfo = await fetchScreener()
            setSocketScreenerData(socketScreenInfo)
        }
        getScreener()
    }, [screenerSettings.updateTable])

    /**
     * Filter and modify the screener info before showing in the UI
     */
    useEffect(() => {
        // console.log(`${Date.now()} asked to filter the data`)
        filterAndSetScreenData()
    }, [socketScreenerData])

    /**
     * Once we have filtered data, use this for voice notification
     */
    useEffect(() => {
        // console.log(`${Date.now()} asked to PING on new momo stock`)
        onScreenerDataUpdated()
    }, [screenerData])

    /**
     * Filter the data and set the timestamp, set different colors based on the range
     */
    const filterAndSetScreenData = (() => {
        if (socketScreenerData) {
            socketScreenerData.map(quote => {
                try {
                    let isLiveUpdated = false
                    if (quote.live && quote.live["quoteTime"] && // Make sure live data is available
                        (quote.live["quoteTime"] - quote.quote["quoteTime"]) > 0 && // live data should be the latest
                        screenerSettings.socketChannel !== "halt" // except for halt, 30 secs data (quote) would be enough
                    ) {
                        isLiveUpdated = true
                    }
                    let timeStamp = new Date(quote.quote["quoteTime"])
                    quote['price'] = Number(quote.quote["lastPrice"].toFixed(2))
                    if (isLiveUpdated) {
                        timeStamp = new Date(quote.live["quoteTime"])
                        quote['price'] = Number(quote.live["lastPrice"].toFixed(2))
                    }
                    // var date = getTimeTwoDigitFormat(timeStamp.getFullYear()) + '-' + getTimeTwoDigitFormat((timeStamp.getMonth() + 1)) + '-' + getTimeTwoDigitFormat(timeStamp.getDate());
                    // var time = getTimeTwoDigitFormat(timeStamp.getHours()) + ":" + getTimeTwoDigitFormat(timeStamp.getMinutes()) + ":" + getTimeTwoDigitFormat(timeStamp.getSeconds());
                    if(screenerSettings.tableType === "halt"){
                        quote.quote["date"] = getTimeFromLong(timeStamp)
                    } else {
                        quote.quote["date"] = getTimeFromLongInHrs(timeStamp)
                    }
                    quote["currentDate"] = getTimeFromLong(new Date())
                    quote['volColor'] = greenYellowRed(quote.quote["totalVolume"])
                    // quote.quote["totalVolume"] = numberConversion(quote.quote["totalVolume"])
                    quote["totalVolume"] = numberConversion(quote.quote["totalVolume"])
                    quote["marketCapFloat"] = 0
                    if (quote.quote["fundamental"]) {
                        quote['floatColor'] = greenYellowRed(quote.quote["fundamental"]["marketCapFloat"] * 1000000)
                        quote["marketCapFloatNumber"] = quote.quote["fundamental"]["marketCapFloat"]
                        quote["marketCapFloat"] = numberConversion(quote.quote["fundamental"]["marketCapFloat"] * 1000000)
                    }

                    // Utilize stats info
                    if (quote.stats && quote.stats["floatShares"]) {
                        // quote['floatColor'] = greenYellowRed(quote?.stats["floatShares"])
                        quote["marketCapFloatNumber"] = quote?.stats["floatShares"]
                        quote["marketCapFloat"] = numberConversion(quote?.stats["floatShares"])
                    }

                    // High of the day info
                    if (quote.quote["highPrice"] > 0) {
                        quote['hod'] = quote['price'] >= quote.quote["highPrice"]
                    }

                    quote['newsColor'] = ""
                    if (screenerSettings.interval === 5 && quote.news) {
                        let newsTimeDiff = ((new Date().getTime() - quote.news['newsTimeInLong']) / 1000) / 60 // in mins
                        if (newsTimeDiff < 10) {
                            quote['newsColor'] = "#ec0000"
                        } else if (newsTimeDiff < 20) {
                            quote['newsColor'] = "#ec5300"
                        } else if (newsTimeDiff < 30) {
                            quote['newsColor'] = "#ecca00"
                        }
                    }
                    return quote
                } catch (error) {
                    console.log(error)
                    console.log(quote)
                }
            })


            // set color based on value
            let updatedScreenerInfo = socketScreenerData
            // if (screenerSettings.socketChannel === "5m3c") console.log(updatedScreenerInfo)
            let uiScreenerData = JSON.parse(JSON.stringify(prevScreenerData.current))
            // if (screenerSettings.socketChannel === "5m3c") console.log(uiScreenerData)
            screenerSettings.colorColumns.map(keyName => {
                updatedScreenerInfo = getColorFunction(socketScreenerData, keyName)
            })
            
            if(screenerSettings.tableType === "halt"){
                updatedScreenerInfo = getColorOnKey(socketScreenerData, false)
            } else {
                updatedScreenerInfo = getColorOnKey(socketScreenerData)
            }

            if (screenerSettings.socketChannel !== "gapper") {
                if (!isFirstTimeDisplay) {
                    // set color for new data
                    updatedScreenerInfo.map(eachNewQuote => {
                        eachNewQuote['symbol-color'] = "var(--new-ticker-bg-color)"
                    })
                } else if (updatedScreenerInfo.length > 0) {
                    setIsFirstTimeDisplay(false)
                }
            }

            // 'isnew' is used for voice alert
            // delete isnew flag from the old data so it doesn't generate the voice
            for (let i = 0; i < uiScreenerData.length; i++) {
                delete uiScreenerData[i]['isnew']
            }

            // Set new data first in the array, add old data if the size of the array is less than maxSizeOfTable
            let maxSizeOfTable = 30
            let tempQuotes = []
            let newAddedData = 0
            updatedScreenerInfo.map(eachNewQuote => {
                // new data should not be the same as old, compare symbol and price
                let shouldReject = uiScreenerData.some(eachOldQuote => {
                    if(eachOldQuote.symbol === eachNewQuote.symbol)
                    {
                        if(screenerSettings.socketChannel == "halt"){
                            return eachOldQuote.price == eachNewQuote.price
                        } else {
                            return eachOldQuote.price >= eachNewQuote.price   
                        }
                    }
                    return false
                })
                if (!shouldReject || screenerSettings.socketChannel === "gapper") {
                    newAddedData++
                    eachNewQuote['isnew'] = true
                    tempQuotes.push(eachNewQuote)
                }
            })

            // if (screenerSettings.socketChannel === "5m3c") console.log('temp quotes', tempQuotes)
            // if (screenerSettings.socketChannel === "5m3c") console.log('uiScreenerData', uiScreenerData)

            // merge new and old data, and slice based on the maxSizeOfTable limit
            uiScreenerData = [...tempQuotes, ...uiScreenerData]
            if (screenerSettings.socketChannel === "gapper" || screenerSettings.socketChannel === "halt") {
                uiScreenerData = uiScreenerData.slice(0, 10)
            } else {
                uiScreenerData = uiScreenerData.slice(0, maxSizeOfTable)
            }

            // if (screenerSettings.socketChannel === "5m3c") console.log(newAddedData)

            // if there is a new data, delete the symbol-color from the old data so they don't highlight
            if (newAddedData > 0) {
                for (let i = newAddedData; i < uiScreenerData.length; i++) {
                    delete uiScreenerData[i]['symbol-color']
                }
            }
            // if (screenerSettings.socketChannel === "5m3c") console.log(uiScreenerData)
            // console.log(uiScreenerData)

            uiScreenerData = filterData(uiScreenerData)
            uiScreenerData = highlightOnNews(uiScreenerData)
            // console.log(uiScreenerData)
            setScreenerData(uiScreenerData)
        }
    })

    /**
     * Hightlight the data based on the last news time with the star after the symbol name
     * @param {*} uiScreenerData 
     */
    const highlightOnNews = (uiScreenerData) => {
        if (uiScreenerData && screenerSettings.interval === 5) {
            uiScreenerData.map(quote => {
                try {
                    quote['newsColor'] = ""
                    if (screenerSettings.interval === 5 && quote.news) {
                        let newsTimeDiff = ((new Date().getTime() - quote.news['newsTimeInLong']) / 1000) / 60 // in mins
                        if (newsTimeDiff < 10) {
                            quote['newsColor'] = "#ec0000"
                        } else if (newsTimeDiff < 20) {
                            quote['newsColor'] = "#ec5300"
                        } else if (newsTimeDiff < 30) {
                            quote['newsColor'] = "#ecca00"
                        }
                    }
                    return quote
                } catch (error) {
                    console.log(error)
                    console.log(quote)
                }
            })
        }
        return uiScreenerData
    }

    /**
     * Filter the data based on the user's preference
     * @param {*} uiScreenerData 
     */
    const filterData = (uiScreenerData) => {
        if (screenerSettings.settings) {
            let dataMap = [
                {
                    settingAccess: "price",
                    accessor: "price"
                },
                {
                    settingAccess: "float",
                    accessor: "quote.fundamental.marketCapFloat"
                },
                {
                    settingAccess: "volume",
                    accessor: "quote.totalVolume"
                },
                {
                    settingAccess: "spread",
                    accessor: "spread"
                },
                {
                    settingAccess: "breakHigh"
                }
            ]
            uiScreenerData = uiScreenerData.filter((eachStock) => {
                let shouldKeep = true

                for (let x in dataMap) {
                    if (screenerSettings.settings[dataMap[x]['settingAccess']]) {
                        if (screenerSettings.settings[dataMap[x]['settingAccess']].minValue) {
                            shouldKeep = _.get(eachStock, dataMap[x].accessor) >= screenerSettings.settings[dataMap[x]['settingAccess']].minValue
                            if (!shouldKeep) break
                        }
                        if (screenerSettings.settings[dataMap[x]['settingAccess']].maxValue) {
                            shouldKeep = _.get(eachStock, dataMap[x].accessor) <= screenerSettings.settings[dataMap[x]['settingAccess']].maxValue
                            if (!shouldKeep) break
                        }
                        if (screenerSettings.settings[dataMap[x]['settingAccess']].value && dataMap[x]['settingAccess'] !== "breakHigh") {
                            shouldKeep = _.get(eachStock, dataMap[x].accessor) <= screenerSettings.settings[dataMap[x]['settingAccess']].value
                            if (!shouldKeep) break
                        }
                        if (screenerSettings.settings[dataMap[x]['settingAccess']].value && helper.isMarketOpen() && dataMap[x]['settingAccess'] === "breakHigh") {
                            if (eachStock.quote["highPrice"] > 0) {
                                shouldKeep = eachStock['price'] >= eachStock.quote["highPrice"]
                                if (!shouldKeep) break
                            }
                        }
                    }
                }
                return shouldKeep
            })
        }
        return uiScreenerData
    }

    /**
     * Process after updating the data
     * Voice notification based on the data
     */
    const onScreenerDataUpdated = () => {
        if ((screenerSettings.interval === 5 || screenerSettings.tableType === "halt") && screenerData.length > 0) {
            let shouldPlay = false
            for (let i = 0; i < screenerData.length; i++) {
                if (screenerData[i]['isnew']) {
                    shouldPlay = true
                    break
                }
            }

            if (shouldPlay && sound) {
                if (screenerSettings.tableType === "halt") {
                    // playSound(playHalted, sound)
                    playHalted()
                } else if (screenerSettings.interval === 5) {
                    // console.log(`${Date.now()} playing a music`)
                    // playSound(playInt5SFX, sound)
                    playInt5SFX()
                }
            }
        }
        prevScreenerData.current = screenerData
    }

    /**
     * API to fetch new screener data
     */
    const fetchScreener = async () => {
        try {
            let momoUrl = `${helper.apiUrl}/momo?`
            if (screenerSettings.interval) {
                momoUrl += `int=${screenerSettings.interval}&`
            }
            if (screenerSettings.change) {
                momoUrl += `change=${screenerSettings.change}&`
            }
            if (screenerSettings.tableType === "halt") {
                momoUrl += `type=halted&`
            }
            const screener = await fetch(momoUrl, { cache: "no-cache" })
            const screenerDataAPI = await screener.json()
            return screenerDataAPI.message
        } catch (error) {
            console.log(`Failed to fetch ${screenerSettings.interval} info`)
            console.log(error)
            return []
        }
    }
    return screenerData
}