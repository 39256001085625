import React, { useEffect, useState, useContext } from 'react'
import _ from 'lodash'
import { toPng, toJpeg } from 'html-to-image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog, faQuestionCircle, faGripHorizontal, faCamera } from '@fortawesome/free-solid-svg-icons'
import { Modal, Button, OverlayTrigger, Tooltip } from 'react-bootstrap'

import useScreener from '../controllers/useScreener'
import useScreenerColumn from '../controllers/useScreenerColumn'
import getTableSettings from '../controllers/tableSettings'

import VanilaScreenerTable from './VanilaScreenerTable'

function Screener({ title, description, interval, change, colorColumns, onChangeConfig, tableType, socketChannel, setOnClick, tableName }) {

    const [settings, setSettings] = useState(getTableSettings(tableName))
    const [localSettings, setLocalSettings] = useState(getTableSettings(tableName))
    const [show, setShow] = useState(false);
    const [updateTable, setUpdateTable] = useState(true)

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    // const columns = useScreenerColumn()
    const columns = useScreenerColumn(tableName)
    const screenerGappers = useScreener({ interval, change, colorColumns, tableType, socketChannel, settings, updateTable })

    function updateSettings(value, name, subDocName) {
        if (["breakHigh", "spread"].includes(name)) {
            let newObj = { ...localSettings }
            newObj[name] = { ...localSettings[name], value }
            setLocalSettings({ ...newObj })
        } else if (["volume", "float", "price"].includes(name)) {
            let newObj = { ...localSettings }
            newObj[name] = (subDocName === "min") ? { ...localSettings[name], minValue: value } : { ...localSettings[name], maxValue: value }
            setLocalSettings({ ...newObj })
        }
    }

    function saveSettings() {
        /* global localStorage */
        localStorage.setItem(tableName, JSON.stringify(localSettings))
        setSettings(JSON.parse(JSON.stringify(localSettings)))
        setUpdateTable(!updateTable)
        setShow(false)
    }

    function resetSettings() {
        localStorage.removeItem(tableName)
        setSettings(getTableSettings(tableName))
        setLocalSettings(getTableSettings(tableName))
        window.location.reload()
    }

    useEffect(() => {
        let localStorageSetting = localStorage.getItem(tableName)
        if (localStorageSetting) {
            localStorageSetting = JSON.parse(localStorageSetting)
            setSettings({ ...localStorageSetting })
            setLocalSettings({ ...localStorageSetting })
        }
    }, [])

    function settingHtml() {
        if (localSettings) {
            let i = 0
            let htmlContent = []
            let numberStyle = {
                width: "90px",
                height: "30px",
                marginTop: "5px",
                fontSize: "13px"
            }
            for (let eachInputKey in localSettings) {
                let eachInputObjectDetails = localSettings[eachInputKey]
                if ("radio" === eachInputObjectDetails['type']) {
                    htmlContent.push(
                        <div className="row mb-2" key={i++}>
                            <div className="col-md-9">
                                <label className="col-form-label form-check-label float-start" htmlFor={tableName + eachInputKey + "id"}>{_.startCase(eachInputKey)}</label>
                            </div>
                            <div className="col-md-3">
                                <div className="form-check form-switch">
                                    <input
                                        className="form-check-input float-end"
                                        checked={eachInputObjectDetails['value']}
                                        onChange={e => updateSettings(e.target.checked, eachInputKey)}
                                        type="checkbox"
                                        id={tableName + eachInputKey + "id"} />
                                </div>
                            </div>
                        </div>
                    )
                } else if ("number" === eachInputObjectDetails['type']) {
                    htmlContent.push(
                        <div className="row mb-2" key={i++}>
                            <div className="col-md-9">
                                <label className="col-form-label form-label float-start" htmlFor={tableName + eachInputKey + "id"}>{_.startCase(eachInputKey)}</label>
                            </div>
                            <div className="col-md-3">
                                <input
                                    className="form-control float-end"
                                    step={eachInputObjectDetails['step']}
                                    value={eachInputObjectDetails['value']}
                                    style={numberStyle}
                                    min={eachInputObjectDetails['min']}
                                    max={eachInputObjectDetails['max']}
                                    onChange={e => updateSettings(e.target.value, eachInputKey)}
                                    type="number"
                                    id={tableName + eachInputKey + "id"} />
                            </div>
                        </div>
                    )
                } else if ("minMax" === eachInputObjectDetails['type']) {
                    htmlContent.push(
                        <div className="row mb-2" key={i++}>
                            <div className="col-md-4">
                                <label className="col-form-label form-label float-start">{_.startCase(eachInputKey)}</label>
                            </div>
                            <div className="col-md-4">
                                <label className="col-form-label form-label float-start" htmlFor={tableName + eachInputKey + "id"}>Min</label>
                                <input
                                    className="form-control float-end"
                                    step={eachInputObjectDetails['step']}
                                    value={eachInputObjectDetails['minValue']}
                                    min={eachInputObjectDetails['min']}
                                    max={eachInputObjectDetails['max']}
                                    onChange={e => updateSettings(e.target.value, eachInputKey, "min")}
                                    style={numberStyle}
                                    type="number"
                                    id={tableName + eachInputKey + "id"} />
                            </div>
                            <div className="col-md-4">
                                <label className="col-form-label form-label float-start" htmlFor={tableName + eachInputKey + "id"}>Max</label>
                                <input
                                    className="form-control float-end"
                                    step={eachInputObjectDetails['step']}
                                    value={eachInputObjectDetails['maxValue']}
                                    min={eachInputObjectDetails['min']}
                                    max={eachInputObjectDetails['max']}
                                    onChange={e => updateSettings(e.target.value, eachInputKey, "max")}
                                    style={numberStyle}
                                    type="number"
                                    id={tableName + eachInputKey + "id"} />
                            </div>
                        </div>
                    )
                }
            }
            return htmlContent
        } else {
            return
        }
    }
    
    async function captureImage(e) {
        e.target.parentNode.closest(".card").querySelector(".card-body").firstElementChild.style.overflow = "hidden" // remove scrollbar to avoid capturing
        toJpeg(e.target.parentNode.closest(".card")).then(function (dataUrl) {
        
            let link = document.createElement('a');
            
            link.href = dataUrl;
            link.download = `${title.replace(" ", "-")}.jpg`;
            
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            e.target.parentNode.closest(".card").querySelector(".card-body").firstElementChild.style.overflow = "auto" // add scrollbar
        })
        .catch(function (error) {
            console.error('oops, something went wrong!', error);
            e.target.parentNode.closest(".card").querySelector(".card-body").firstElementChild.style.overflow = "auto" // add scrollbar
        });
    }

    return (
        <div className="card"
            style={{
                overflow: "auto",
                resize: "both",
            }}>
            <div className="card-header">
                <div className="container d-flex justify-content-between">
                    <div className="align-self-start">
                        {title}
                        <OverlayTrigger
                            key='right'
                            placement='right'
                            overlay={
                                <Tooltip id={`tooltip-right`}>
                                    {description}
                                </Tooltip>
                            }
                        >
                            <div style={{ display: "inline-block" }}><FontAwesomeIcon variant="secondary" icon={faQuestionCircle} style={{ fontSize: "0.8rem", margin: "0 0 1px 10px", cursor: "pointer" }} /></div>
                        </OverlayTrigger>
                    </div>
                    <FontAwesomeIcon className="dragHandle" variant="secondary" icon={faGripHorizontal} style={{ fontSize: "1.1rem", margin: "5px 10px 0px" }} />
                    <div className="d-flex">
                        {tableName !== "halt" && <FontAwesomeIcon variant="secondary" icon={faCog} style={{ fontSize: "1.1rem", margin: "5px 10px 0px", cursor: "pointer" }} onClick={handleShow} />}
                        {tableName === "halt" && <div></div>}
                        <FontAwesomeIcon variant="secondary" icon={faCamera} className="image-capture-icon" onClick={ captureImage } />
                    </div>
                </div>
            </div>
            <div className="card-body">
                <VanilaScreenerTable columns={columns} data={screenerGappers} setOnClick={setOnClick} tableName={tableName} />
            </div>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {title}
                        <OverlayTrigger
                            key='right'
                            placement='right'
                            overlay={
                                <Tooltip id={`tooltip-right`}>
                                    {description}
                                </Tooltip>
                            }
                        >
                            <div style={{ display: "inline-block" }}><FontAwesomeIcon variant="secondary" icon={faQuestionCircle} style={{ fontSize: "0.8rem", margin: "0 0 1px 10px", cursor: "pointer" }} /></div>
                        </OverlayTrigger>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {settingHtml()}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={resetSettings}>
                        Reset
                    </Button>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={saveSettings}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default Screener
