import { useState, useEffect, useRef, useContext } from 'react'
import $ from 'jquery'
import { createChart, CrosshairMode } from 'lightweight-charts'

import { timeToTz, numberConversion } from '../utils/jshelper'

import helper from './helper'

const MIN1 = "1m"
const MIN5 = "5m"
const DAILY = "D"
let intervals = [MIN1, MIN5, DAILY];

export default function useTradingViewChart(ticker, defaultIntervalIndex, chartIdName, channelName, shouldInitiateChannel = 1) {

    let lastChartTime = new Date().getTime()
    let intervalObj = ""

    let candlestickSeries = null
    let volumeSeries = null
    let vwapSeries = null

    let ema1Series = null
    let ema2Series = null
    let lastEMA = []

    let luldUSeries = null
    let luldDSeries = null

    let selectedIntervalIndex = useRef(defaultIntervalIndex)
    let chart = null
    let switcherElement = null
    let mouseIn = false
    let socketListner = null

    let prevTickerObj = useRef({})

    useEffect(() => {
        $(`#${chartIdName}`).on('mouseleave', function () {
            mouseIn = false
        })
    }, [ticker])

    useEffect(() => {
        $(`#${chartIdName}`).on('mouseover', function () {
            mouseIn = true
        })
    }, [ticker])

    /*useEffect(() => {
        if (intervalObj) {
            clearInterval(intervalObj)
        }
        intervalObj = setInterval(() => {
            let currentTime = new Date().getTime()
            if ((currentTime - lastChartTime) > 5000) {
                if (shouldInitiateChannel) {
                    const socket = helper.getSocketConn()
                    socket.emit(channelName, ticker.trim())
                }
            }
        }, 5000)
    }, [])*/

    /**
     * use socket connection to listen live quote info
     */
    useEffect(() => {
        const socket = helper.getSocketConn()
        socketListner = socket.on(channelName, msg => {
            let newChartData
            try {
                lastChartTime = new Date().getTime()
                if (msg && msg.data && msg.data[0] && msg.data[0].live && msg.data[0].live.symbol.toUpperCase() === ticker.toUpperCase()) {
                    // if(msg.data[0].live.symbol.toUpperCase() == "DYNT") debugger
                    let prevTickerObjCopy = JSON.parse(JSON.stringify(prevTickerObj.current))
                    newChartData = updateChartData(prevTickerObjCopy, msg.data[0])
                    if (candlestickSeries && newChartData && newChartData?.length > 1) {
                        // debugger
                        // prevTickerObj.current = newChartData[newChartData.length - 1]
                        candlestickSeries.update(newChartData[newChartData.length - 1])
                        // debugger
                        if (!mouseIn) {
                            setLegendText(newChartData[newChartData.length - 1],
                                newChartData[newChartData.length - 1]?.volumeData?.value,
                                newChartData[newChartData.length - 1].vwap ? newChartData[newChartData.length - 1].vwap.value : "",
                                newChartData[newChartData.length - 1]["ema1"] ? newChartData[newChartData.length - 1]["ema1"].value : "",
                                newChartData[newChartData.length - 1]["ema2"] ? newChartData[newChartData.length - 1]["ema2"].value : ""
                            )
                        }
                        // update volume data LIVE
                        if (volumeSeries && newChartData[newChartData.length - 1].volumeData && newChartData[newChartData.length - 1].volumeData.time) {
                            volumeSeries.update(newChartData[newChartData.length - 1].volumeData)
                        }

                        // update vwap data LIVE
                        if (vwapSeries && newChartData[newChartData.length - 1].vwap && newChartData[newChartData.length - 1].vwap.time) {
                            vwapSeries.update(newChartData[newChartData.length - 1].vwap)
                        }

                        // update EMA1 data LIVE
                        if (ema1Series && newChartData[newChartData.length - 1]["ema1"] && newChartData[newChartData.length - 1]["ema1"].time) {
                            ema1Series.update(newChartData[newChartData.length - 1]["ema1"])
                        }

                        // update EMA2 data LIVE
                        if (ema2Series && newChartData[newChartData.length - 1]["ema2"] && newChartData[newChartData.length - 1]["ema2"].time) {
                            ema2Series.update(newChartData[newChartData.length - 1]["ema2"])
                        }

                        if ((Date.now() - msg.data[0]?.luld?.timeInLong) < 58000) { // in milliseconds (58 seconds)
                            // update luldU data LIVE
                            if (luldUSeries && msg.data[0]?.luld?.u) {
                                luldUSeries.setData([{ time: msg.data[0]?.luld?.timeInLong, value: msg.data[0]?.luld?.u }])
                            }
                            // update luldD data LIVE
                            if (luldDSeries && msg.data[0]?.luld?.d) {
                                luldDSeries.setData([{ time: msg.data[0]?.luld?.timeInLong, value: msg.data[0]?.luld?.d }])
                            }
                        } else {
                            if (luldUSeries) luldUSeries.setData([])
                            if (luldDSeries) luldDSeries.setData([])
                        }
                    }
                }
            }
            catch (e) {
                console.log(`Issue while getting socket data for a chart`, e)
                console.log(`Socket message`, msg)
                console.log(`New data`, newChartData[newChartData.length - 1])
            }
        });

        return (() => {
            const socket = helper.getSocketConn()
            socket.emit(`stop${channelName}`)
            // socket.off(channelName)
        })
    }, [ticker])


    /**
     * use socket connection to send update ticker info
     */
    useEffect(() => {
        $(`#${chartIdName} .ohlc-legend`).remove()
        if (shouldInitiateChannel) {
            const socket = helper.getSocketConn()
            socket.emit(channelName, ticker.trim())
        }
    }, [ticker])


    /**
     * Get ticker price history
     */
    useEffect(() => {
        const getTickerPriceHistory = (async () => {
            try {
                // clear previous chart
                prevTickerObj.current = {}
                if (chart) {
                    candlestickSeries = null
                    volumeSeries = null
                    vwapSeries = null
                    ema1Series = null
                    ema2Series = null
                    luldUSeries = null
                    luldDSeries = null
                    chart.remove()
                    chart = null
                }
                $(`#${chartIdName}`).empty()

                switcherElement = createSimpleSwitcher(intervals, intervals[selectedIntervalIndex.current], syncToInterval);

                chart = createChart(chartIdName, {
                    width: $(`#${chartIdName}`).parent().width(),
                    height: $(`#${chartIdName}`).parent().height() - 30,
                    autoScale: true,
                    timeScale: {
                        timeVisible: true,
                        secondsVisible: false
                    },
                    layout: {
                        background: { color: '#181b2e' },
                        textColor: '#d1d4dc'
                    },
                    grid: {
                        vertLines: {
                            color: 'rgba(58, 66, 80, 0.3)',
                        },
                        horzLines: {
                            color: 'rgba(58, 66, 80, 0.3)',
                        },
                    },
                    crosshair: {
                        mode: CrosshairMode.Normal,
                    }
                });

                // resize chart - responsive
                let chartHtml = $(`#${chartIdName}`).closest(`.card-body`)[0]
                new ResizeObserver(entries => {
                    if (entries.length === 0 || entries[0].target !== chartHtml) { return }
                    const newRect = entries[0].contentRect
                    chart.applyOptions({ height: newRect.height - 30, width: newRect.width })
                }).observe(chartHtml)

                chart.subscribeCrosshairMove(param => {
                    if (param.time) {
                        setLegendText(param.seriesPrices.get(candlestickSeries),
                            param.seriesPrices.get(volumeSeries),
                            param.seriesPrices.get(vwapSeries),
                            param.seriesPrices.get(ema1Series),
                            param.seriesPrices.get(ema2Series)
                        )
                    }
                })


                async function syncToInterval(intervalName) {
                    intervals.forEach((intervalValue, intervalIndex) => {
                        if (intervalValue === intervalName) {
                            selectedIntervalIndex.current = intervalIndex
                        }
                    })

                    chart.applyOptions({
                        watermark: {
                            visible: true,
                            fontSize: 24,
                            horzAlign: 'center',
                            vertAlign: 'center',
                            color: '#424450',
                            text: `${ticker} ${intervalName}`,
                        },
                    });

                    if (candlestickSeries) {
                        chart.removeSeries(candlestickSeries);
                        candlestickSeries = null;
                    }
                    if (volumeSeries) {
                        chart.removeSeries(volumeSeries);
                        volumeSeries = null;
                    }
                    if (vwapSeries) {
                        chart.removeSeries(vwapSeries);
                        vwapSeries = null;
                    }

                    if (ema1Series) {
                        chart.removeSeries(ema1Series);
                        ema1Series = null;
                    }

                    if (ema2Series) {
                        chart.removeSeries(ema2Series);
                        ema2Series = null;
                    }

                    if (luldUSeries) {
                        chart.removeSeries(luldUSeries)
                        luldUSeries = null
                    }
                    if (luldDSeries) {
                        chart.removeSeries(luldDSeries)
                        luldDSeries = null
                    }

                    if (intervalName === DAILY) {
                        chart.applyOptions({
                            timeScale: {
                                timeVisible: false
                            }
                        })
                    } else {
                        chart.applyOptions({
                            timeScale: {
                                timeVisible: true
                            }
                        })
                    }
                    candlestickSeries = chart.addCandlestickSeries();
                    volumeSeries = chart.addHistogramSeries({
                        color: '#26a69a',
                        priceFormat: {
                            type: 'volume',
                        },
                        priceScaleId: '',
                        scaleMargins: {
                            top: 0.8,
                            bottom: 0,
                        },
                    })

                    let [barData, volumeData] = await getBarData(intervalName)

                    // calculate EMA values for the data
                    let ema1Data = []
                    let ema2Data = []
                    if (intervalName !== DAILY) {
                        let vwapData = calculateVWAP([barData, volumeData], 100);
                        vwapSeries = chart.addLineSeries({
                            color: '#ffeb3b',
                            lineWidth: 1,
                            lineStyle: 3,
                            priceLineVisible: false,
                        });
                        vwapSeries.setData(vwapData);

                        // EMA series
                        ema1Data = calculateEMA("ema1", [barData, volumeData], 9);
                        ema2Data = calculateEMA("ema2", [barData, volumeData], 20);

                        ema1Series = chart.addLineSeries({
                            color: '#4d7cff',
                            lineWidth: 2,
                            lineStyle: 0,
                            priceLineVisible: false,
                        });
                        ema1Series.setData(ema1Data);

                        ema2Series = chart.addLineSeries({
                            color: '#FF0080',
                            lineWidth: 2,
                            lineStyle: 0,
                            priceLineVisible: false,
                        });
                        ema2Series.setData(ema2Data);

                        luldUSeries = chart.addLineSeries({
                            color: '#00c953',
                            lineWidth: 0,
                            lineStyle: 0,
                            priceLineVisible: false,
                            title: 'HALT UP'
                        });

                        luldDSeries = chart.addLineSeries({
                            color: '#fb8c01',
                            lineWidth: 0,
                            lineStyle: 0,
                            priceLineVisible: false,
                            title: 'HALT DOWN'
                        });
                    } else {
                        ema1Data = calculateEMA("ema1", [barData, volumeData], 100);
                        ema2Data = calculateEMA("ema2", [barData, volumeData], 200);

                        ema1Series = chart.addLineSeries({
                            color: '#4d7cff',
                            lineWidth: 2,
                            lineStyle: 0,
                            priceLineVisible: false,
                        });
                        ema1Series.setData(ema1Data);

                        ema2Series = chart.addLineSeries({
                            color: '#FF0080',
                            lineWidth: 2,
                            lineStyle: 0,
                            priceLineVisible: false,
                        });
                        ema2Series.setData(ema2Data);
                    }
                    if (barData) candlestickSeries.setData(barData);
                    if (volumeData) volumeSeries.setData(volumeData);

                    setLegendText(
                        barData[barData.length - 1],
                        volumeData[volumeData.length - 1].value,
                        barData[barData.length - 1].vwap ? barData[barData.length - 1].vwap.value : "",
                        ema1Data[ema1Data.length - 1] ? ema1Data[ema1Data.length - 1].value : "",
                        ema2Data[ema2Data.length - 1] ? ema2Data[ema2Data.length - 1].value : "",
                    )
                }

                let tradeViewElement = document.getElementById(chartIdName)
                if (tradeViewElement) tradeViewElement.prepend(switcherElement);
                syncToInterval(intervals[selectedIntervalIndex.current])

            }
            catch (error) {
                console.log(`Failed to fetch ${ticker} info in useTradingViewChart`)
                console.log(error)
                return []
            }
        })
        getTickerPriceHistory()
    }, [ticker])


    function calculateSMA(data, count) {
        let barData = data[0]
        let volumeData = data[1]
        let avg = function (data) {
            let sum = 0;
            for (let i = 0; i < data.length; i++) {
                sum += data[i].close;
            }
            return sum / data.length;
        };
        let result = [];
        for (let i = count - 1, len = barData.length; i < len; i++) {
            let val = avg(barData.slice(i - count + 1, i));
            result.push({ time: barData[i].time, value: val });
        }
        return result;
    }

    function calculateVWAP(data, count) {
        let barData = data[0]
        let volumeData = data[1]
        let result = [];
        for (let i = 0; i < barData.length; i++) {
            result.push({ time: barData[i].time, value: barData[i].vwap.value });
        }
        return result;
    }


    function calculateEMA(emaName, data, range) {
        let barData = data[0]
        let volumeData = data[1]
        let emaArray = []
        let k = 2 / (range + 1);
        // first item is just the same as the first item in the input
        emaArray = [{ time: barData[0].time, value: barData[0].close }]
        barData[0][emaName] = { time: barData[0].time, value: barData[0].close, range }
        lastEMA[emaName] = { value: barData[0].close, range }
        // for the rest of the items, they are computed with the previous one
        for (let i = 1; i < barData.length; i++) {
            let preEMAVal = emaArray[i - 1].value
            let newEMA = (barData[i].close * k) + (preEMAVal * (1 - k))
            newEMA = Number(newEMA)
            emaArray.push({ time: barData[i].time, value: newEMA, range })
            lastEMA[emaName] = { value: newEMA, range }
            barData[i][emaName] = { time: barData[i].time, value: newEMA, range }
        }
        return emaArray;
    }

    function getEMA(curretValue, prevEMA, range) {
        let k = 2 / (range + 1);
        return (curretValue * k) + (prevEMA * (1 - k))
    }

    function getBarData(interval) {
        return new Promise(async (resolve, reject) => {
            try {
                if (!ticker) return resolve([])
                let chartData = []
                let volumeData = []

                let screenerDataAPI = []
                if (interval === MIN1 || interval === MIN5) {
                    let tickerDataResPromise = await fetch(`${helper.apiUrl}/s/ticker/chart?q=${ticker}&interval=${interval}`)
                    screenerDataAPI = await tickerDataResPromise.json();
                } else if (interval === DAILY) {
                    let tickerDataResPromise = await fetch(`${helper.apiUrl}/alpaca/stock?q=${ticker}`)
                    screenerDataAPI = await tickerDataResPromise.json();
                }

                let [filteredHistoryData, filteredHistoryVolumeData] = await getChartCompatibleData(interval, screenerDataAPI)

                chartData = JSON.parse(JSON.stringify(filteredHistoryData))
                volumeData = JSON.parse(JSON.stringify(filteredHistoryVolumeData))

                return resolve([chartData, volumeData])
            }
            catch (error) {
                console.log(`Failed in getBarData()`)
                console.log(error)
                return resolve([])
            }
        })
    }

    function getChartCompatibleData(interval, data) {
        return new Promise(async (resolve, reject) => {
            try {
                let accessKey = "history1mins"
                if (interval === MIN5) accessKey = "history5mins"
                if (interval === DAILY) accessKey = "history1day"
                if (data.message && data.message[accessKey]) {
                    let historyData = data.message[accessKey]
                    if (interval !== DAILY) {
                        historyData = data.message[accessKey].reverse()
                    }
                    let filteredHistoryData = []
                    let filteredHistoryVolumeData = []
                    let cumulativePV = 0
                    let totalVolumeCalc = 0
                    for (let i = 1; i < historyData.length; i++) {
                        try {
                            let tradeViewCompatibleObj = {}
                            let tickerData = historyData[i]
                            let dateOfNewTicker
                            if (interval === DAILY) {
                                if (tickerData.t) {
                                    dateOfNewTicker = new Date(tickerData.t)
                                    tradeViewCompatibleObj.quoteTime = new Date(tickerData.t).getTime()
                                } else {
                                    continue
                                }
                            } else {
                                if (tickerData?.[0] && tickerData?.[1] && tickerData?.[2] && tickerData?.[3] && tickerData?.[4] && tickerData?.[5]) {
                                    dateOfNewTicker = new Date(tickerData[5])
                                    tradeViewCompatibleObj.quoteTime = tickerData[5]
                                } else {
                                    continue
                                }
                            }
                            let utc_timestamp = Date.UTC(dateOfNewTicker.getFullYear(), dateOfNewTicker.getMonth(), dateOfNewTicker.getDate(),
                                dateOfNewTicker.getHours(), dateOfNewTicker.getMinutes(), 0, 0)

                            if (interval === MIN5) {
                                utc_timestamp = Date.UTC(dateOfNewTicker.getFullYear(), dateOfNewTicker.getMonth(), dateOfNewTicker.getDate(),
                                    dateOfNewTicker.getHours(), dateOfNewTicker.getMinutes() + 1, 0, 0)
                            }

                            tradeViewCompatibleObj.time = utc_timestamp / 1000
                            historyData[i].time = utc_timestamp / 1000

                            if (interval == DAILY) {
                                tradeViewCompatibleObj.time = dateOfNewTicker.getTime() / 1000
                                historyData[i].time = dateOfNewTicker.getTime() / 1000
                            }

                            if (interval === DAILY) {
                                tradeViewCompatibleObj.open = tickerData.o
                                tradeViewCompatibleObj.high = tickerData.h
                                tradeViewCompatibleObj.low = tickerData.l
                                tradeViewCompatibleObj.close = tickerData.c
                                tradeViewCompatibleObj.volume = tickerData.v
                                tradeViewCompatibleObj.totalVolume = tickerData.v
                            } else {
                                tradeViewCompatibleObj.open = tickerData[0]
                                tradeViewCompatibleObj.high = tickerData[1]
                                tradeViewCompatibleObj.low = tickerData[2]
                                tradeViewCompatibleObj.close = tickerData[3]
                                tradeViewCompatibleObj.totalVolume = historyData[i][4]


                                if (historyData[i][4] > historyData?.[i - 1]?.[4]) { // for the regular data calculation
                                    tradeViewCompatibleObj.volume = historyData[i][4] - historyData[i - 1][4]
                                } else { // it for the new day first volume info
                                    tradeViewCompatibleObj.volume = historyData[i][4]
                                }


                                // calculating vwap                               
                                tradeViewCompatibleObj.vwap = {
                                    time: tradeViewCompatibleObj.time
                                }
                                let avgP = (tradeViewCompatibleObj.high + tradeViewCompatibleObj.low + tradeViewCompatibleObj.close) / 3
                                if (historyData[i][4] > historyData[i - 1][4]) {
                                    totalVolumeCalc = totalVolumeCalc + tradeViewCompatibleObj.volume
                                    cumulativePV += (avgP * tradeViewCompatibleObj.volume)
                                    tradeViewCompatibleObj.vwap.value = cumulativePV / totalVolumeCalc
                                } else {
                                    // reset cumulative PV
                                    totalVolumeCalc = tradeViewCompatibleObj.volume
                                    cumulativePV = avgP * tradeViewCompatibleObj.volume
                                    tradeViewCompatibleObj.vwap.value = avgP
                                }
                                // assign vwap object for future calculation (for live data)
                                tradeViewCompatibleObj.vwap.time = tradeViewCompatibleObj.time
                                tradeViewCompatibleObj.vwap.totalVolumeCalc = totalVolumeCalc
                                tradeViewCompatibleObj.vwap.cumulativePV = cumulativePV
                            }

                            if ((historyData[i - 1] && historyData[i - 1].time < historyData[i].time)) { // make sure same time bar doesn't show here (lightweight will throw an error)
                                filteredHistoryData.push(tradeViewCompatibleObj)

                                // data for volume bar
                                filteredHistoryVolumeData.push({
                                    time: tradeViewCompatibleObj.time,
                                    quoteTime: tradeViewCompatibleObj.quoteTime,
                                    value: tradeViewCompatibleObj.volume,
                                    color: Number(tradeViewCompatibleObj.open) > Number(tradeViewCompatibleObj.close) ? "#ef5350" : "#26a69a",
                                })
                            }
                        } catch (error) {
                            console.log(`Failed in getChartCompatibleData()`)
                            console.log(error)
                        }
                    }
                    if (filteredHistoryData && filteredHistoryData.length > 0) prevTickerObj.current = JSON.parse(JSON.stringify(filteredHistoryData))
                    return resolve([filteredHistoryData, filteredHistoryVolumeData])
                }
            }
            catch (error) {
                console.log(`Failed in getChartCompatibleData()`)
                console.log(error)
                return resolve([])
            }
        })
    }

    // Default function for multiple timeframe
    function createSimpleSwitcher(items, activeItem, activeItemChangedCallback) {
        let switcherElement = document.createElement('div');
        switcherElement.classList.add('switcher');
        switcherElement.style.display = 'block';
        switcherElement.style.position = 'absolute';
        switcherElement.style.left = '40px';
        switcherElement.style.bottom = '10px';
        switcherElement.style.zIndex = 2;
        switcherElement.style.fontSize = '12px';

        let intervalElements = items.map(function (item) {
            let itemEl = document.createElement('button');
            itemEl.innerText = item;
            itemEl.classList.add('switcher-item');
            itemEl.classList.toggle('switcher-active-item', item === activeItem);
            itemEl.addEventListener('click', function () {
                onItemClicked(item);
            });
            switcherElement.appendChild(itemEl);
            return itemEl;
        });

        function onItemClicked(item) {
            if (item === activeItem) {
                return;
            }

            intervalElements.forEach(function (element, index) {
                element.classList.toggle('switcher-active-item', items[index] === item);
            });

            activeItem = item;

            activeItemChangedCallback(item);
        }

        return switcherElement;
    }


    function setLegendText(priceValue, volume, vwapValue, ema1Value, ema2Value) {
        $(`#${chartIdName} .ohlc-legend`).remove()

        let legend = document.createElement('div');
        legend.className = 'ohlc-legend';
        let tradeViewElement = document.getElementById(chartIdName)
        if (tradeViewElement) tradeViewElement.prepend(legend);
        legend.style.display = 'block';
        legend.style.position = 'absolute';
        legend.style.left = '40px';
        legend.style.top = '65px';
        legend.style.zIndex = 2;
        legend.style.fontSize = '12px';

        let diff = "n/a"
        if (priceValue !== undefined) {
            priceValue.open = priceValue.open ? Number((priceValue.open)).toFixed(2) : 'n/a'
            priceValue.high = priceValue.high ? Number((priceValue.high)).toFixed(2) : 'n/a'
            priceValue.low = priceValue.low ? Number((priceValue.low)).toFixed(2) : 'n/a'
            priceValue.close = priceValue.close ? Number((priceValue.close)).toFixed(2) : 'n/a'

            if (priceValue.open && priceValue.close) diff = (((priceValue.close - priceValue.open) / priceValue.open) * 100).toFixed(2)
        }
        // up: #26a69a, down: #ef5350

        let barColor = "#26a69a"
        let diffNumber = ""
        if (diff < 0) {
            barColor = "#ef5350"
        } else {
            diff = `+${diff}`
            diffNumber = `+`
        }
        if (priceValue && priceValue.close && priceValue.open) diffNumber += (priceValue.close - priceValue.open).toFixed(2)
        if (priceValue !== undefined) {
            legend.innerHTML = `
            <span style="color: ${barColor}">
                <span>
                    <span style="color: #d1d4dc">O </span> ${priceValue.open}
                    <span style="color: #d1d4dc">H </span> ${priceValue.high}
                    <span style="color: #d1d4dc">L </span> ${priceValue.low}
                    <span style="color: #d1d4dc">C </span> ${priceValue.close}
                     ${diffNumber} (${diff}%)
                    <span style="color: #d1d4dc">Vol </span> ${numberConversion(volume)}
                    ${vwapValue ? `
                        <br>
                        <span style="color: #d1d4dc">VWAP </span> <span style="color: #ffeb3b">${vwapValue.toFixed(2)}</span>
                    ` : ``}
                    <br>
                    ${intervals[selectedIntervalIndex.current] !== DAILY && ema1Value ? `
                        <span style="color: #d1d4dc">EMA9 </span> <span style="color: #4d7cff">${ema1Value.toFixed(2)}</span>
                    ` : ``}
                    ${intervals[selectedIntervalIndex.current] !== DAILY && ema2Value ? `
                        <span style="color: #d1d4dc">EMA20 </span> <span style="color: #FF0080">${ema2Value.toFixed(2)}</span>
                    ` : ``}
                    
                    ${intervals[selectedIntervalIndex.current] === DAILY && ema1Value ? `
                        <span style="color: #d1d4dc">EMA100 </span> <span style="color: #4d7cff">${ema1Value.toFixed(2)}</span>
                    ` : ``}
                    ${intervals[selectedIntervalIndex.current] === DAILY && ema2Value ? `
                        <span style="color: #d1d4dc">EMA200 </span> <span style="color: #FF0080">${ema2Value.toFixed(2)}</span>
                    ` : ``}
                </span>
            </span>`
        }
    }

    // based on the history ticker data API, find OHLC and update the object
    function updateChartData(chartData, newTicker) {
        try {
            if (!chartData || !chartData?.[chartData.length - 1] || !newTicker) return
            let newTickerLive = newTicker?.live
            // debugger
            if (newTickerLive?.quoteTime && newTickerLive?.lastPrice && newTickerLive?.totalVolume) {
                let currentBar = {
                    open: null,
                    high: null,
                    low: null,
                    close: null,
                    totalVolume: null,
                    volume: null,
                    volumeData: {
                        value: null,
                        color: null
                    },
                    vwap: {
                        value: null,
                        totalVolumeCalc: 0,
                        cumulativePV: 0
                    }
                }
                let dateOfNewTicker = new Date(newTickerLive.quoteTime)
                let lastChartCandleDate = new Date(chartData[chartData.length - 1].quoteTime)
                if (!chartData[chartData.length - 1].quoteTime) lastChartCandleDate = new Date(chartData[chartData.length - 1].Timestamp)
                // let dateOfNewTicker = new Date(new Date(newTickerLive.quoteTime).toLocaleString("en-US", { timeZone: "America/New_York" }));
                if (chartData && chartData.length > 0) {
                    if (isOnSameTimeFrame(dateOfNewTicker, lastChartCandleDate)) {
                        currentBar = chartData[chartData.length - 1]
                    }
                }


                currentBar.quoteTime = newTickerLive.quoteTime
                let prevBar = chartData[chartData.length - 1]
                if (currentBar.time) { // updating the same bar
                    prevBar = chartData[chartData.length - 2]
                    // let utc_timestamp = Date.UTC(dateOfNewTicker.getFullYear(), dateOfNewTicker.getMonth(), dateOfNewTicker.getDate(),
                    //     dateOfNewTicker.getHours(), dateOfNewTicker.getMinutes(), 0, 0);
                    // currentBar.time = (utc_timestamp) / 1000

                    currentBar.close = Number(newTickerLive.lastPrice);
                    currentBar.high = Math.max(Number(currentBar.high), Number(newTickerLive.lastPrice))
                    currentBar.low = Math.min(Number(currentBar.low), Number(newTickerLive.lastPrice))
                    currentBar.volume = newTickerLive.totalVolume - (currentBar.totalVolume - currentBar.volume)
                    currentBar.totalVolume = newTickerLive.totalVolume

                    if (!currentBar.volumeData) {
                        currentBar.volumeData = {}
                        currentBar.volumeData.time = currentBar.time
                    }

                    // for vwap calculation
                    if (intervals[selectedIntervalIndex.current] !== DAILY) {
                        let avgP = (currentBar.high + currentBar.low + currentBar.close) / 3
                        currentBar.vwap.time = currentBar.time
                        currentBar.vwap.totalVolumeCalc = prevBar?.vwap?.totalVolumeCalc + currentBar.volume
                        currentBar.vwap.cumulativePV = prevBar?.vwap?.cumulativePV + (avgP * currentBar.volume)
                        currentBar.vwap.value = currentBar?.vwap?.cumulativePV / currentBar.vwap.totalVolumeCalc
                    }

                    // set volume
                    currentBar.volumeData.value = currentBar.volume
                    currentBar.volumeData.color = Number(currentBar.open) > Number(currentBar.close) ? "#ef5350" : "#26a69a"


                    if (intervals[selectedIntervalIndex.current] === DAILY && newTicker.quote && newTicker.quote.openPrice) {
                        currentBar.open = newTicker.quote.openPrice
                        currentBar.high = newTicker.quote.highPrice
                        currentBar.low = newTicker.quote.lowPrice
                        currentBar.close = newTickerLive.lastPrice
                    }

                    // calculate EMA values
                    if (lastEMA["ema1"] && lastEMA["ema1"]["value"]) {
                        let updatedEMA = getEMA(currentBar.close, lastEMA["ema1"]["value"], lastEMA["ema1"]["range"])
                        // currentBar["ema1"]["value"] = updatedEMA
                        currentBar["ema1"] = { time: currentBar.time, value: updatedEMA }
                    }
                    if (lastEMA["ema2"] && lastEMA["ema2"]["value"]) {
                        let updatedEMA = getEMA(currentBar.close, lastEMA["ema2"]["value"], lastEMA["ema2"]["range"])
                        // currentBar["ema2"]["value"] = updatedEMA
                        currentBar["ema2"] = { time: currentBar.time, value: updatedEMA }
                    }
                } else {
                    let utc_timestamp = Date.UTC(dateOfNewTicker.getFullYear(), dateOfNewTicker.getMonth(), dateOfNewTicker.getDate(),
                        dateOfNewTicker.getHours(), dateOfNewTicker.getMinutes(), 0, 0);
                    currentBar.time = (utc_timestamp) / 1000
                    currentBar.open = newTickerLive.lastPrice
                    currentBar.high = newTickerLive.lastPrice
                    currentBar.low = newTickerLive.lastPrice
                    currentBar.close = newTickerLive.lastPrice
                    currentBar.totalVolume = newTickerLive.totalVolume
                    // calculate volume
                    currentBar.volume = newTickerLive.totalVolume - (currentBar.totalVolume - currentBar.volume)

                    if (intervals[selectedIntervalIndex.current] === DAILY && newTicker.quote && newTicker.quote.openPrice) {
                        currentBar.open = newTicker.quote.openPrice
                        currentBar.high = newTicker.quote.highPrice
                        currentBar.low = newTicker.quote.lowPrice
                        currentBar.close = newTickerLive.lastPrice
                    }


                    currentBar.volumeData = {
                        time: currentBar.time,
                        value: currentBar.volume,
                        color: Number(currentBar.open) > Number(currentBar.close) ? "#ef5350" : "#26a69a",
                    }

                    if (prevBar && intervals[selectedIntervalIndex.current] !== DAILY && prevBar.vwap && prevBar.vwap.totalVolumeCalc !== undefined) {
                        // vwap data
                        let avgP = (currentBar.high + currentBar.low + currentBar.close) / 3
                        currentBar.vwap = {
                            totalVolumeCalc: prevBar.vwap.totalVolumeCalc + currentBar.volume,
                            cumulativePV: prevBar.vwap.cumulativePV + (avgP * currentBar.volume),
                        }
                        currentBar.vwap.time = currentBar.time
                        currentBar.vwap.value = currentBar.vwap.cumulativePV / currentBar.vwap.totalVolumeCalc
                    }

                    // calculate EMA values
                    if (lastEMA["ema1"] && lastEMA["ema1"]["value"]) {
                        let updatedEMA = getEMA(currentBar.close, lastEMA["ema1"]["value"], lastEMA["ema1"]["range"])
                        lastEMA["ema1"]["value"] = updatedEMA
                        currentBar["ema1"] = { time: currentBar.time, value: updatedEMA }
                    }
                    if (lastEMA["ema2"] && lastEMA["ema2"]["value"]) {
                        let updatedEMA = getEMA(currentBar.close, lastEMA["ema2"]["value"], lastEMA["ema2"]["range"])
                        lastEMA["ema2"]["value"] = updatedEMA
                        currentBar["ema2"] = { time: currentBar.time, value: updatedEMA }
                    }

                    // push the new bar
                    // It actually creates the new bar
                    chartData.push(currentBar)

                    // only update the prevbar REF if there is a new bar
                }
            }
        } catch (error) {
            console.log(`Failed to set new socket data`)
            console.log(error)
        } finally {
            prevTickerObj.current = JSON.parse(JSON.stringify(chartData))
            return chartData
        }
    }

    function isOnSameTimeFrame(d1, d2) {
        if (intervals[selectedIntervalIndex.current] === MIN1) {
            return (d1.getFullYear() === d2.getFullYear() &&
                d1.getMonth() === d2.getMonth() && d1.getDate() === d2.getDate() &&
                d1.getHours() === d2.getHours() && d1.getMinutes() === d2.getMinutes())
        } else if (intervals[selectedIntervalIndex.current] === MIN5) {
            return (d1.getFullYear() === d2.getFullYear() &&
                d1.getMonth() === d2.getMonth() && d1.getDate() === d2.getDate() &&
                d1.getHours() === d2.getHours() && ((Math.floor(d1.getMinutes() / 5)) == (Math.floor(d2.getMinutes() / 5))))
        } else if (intervals[selectedIntervalIndex.current] === DAILY) {
            return (d1.getFullYear() === d2.getFullYear() &&
                d1.getMonth() === d2.getMonth() && d1.getDate() === d2.getDate())
        }
    }

    return chart
}
