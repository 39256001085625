import { useRef, useState, PureComponent } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGripHorizontal } from '@fortawesome/free-solid-svg-icons'
import {
    BarChart,
    Bar,
    Brush,
    Cell,
    ReferenceLine,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer
}
from "recharts";

export default function CBarChartByDay({ data }) {
    if (!data || data.length === 0) return (<></>)
    const dollarFormatter = item => item && `$${item}`

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className="recharts-custom-tooltip d-flex flex-column">
                <div className="recharts-label">{`Date: ${label}`}</div>
                <div className="recharts-label">{`Profit/Loss : $${(payload[0].value).toFixed(2)}`}</div>
                <div className="recharts-label">{`Volume per Trade : ${(payload[0].payload.volume / payload[0].payload.transactions).toFixed(2)}`}</div>
                <div className="recharts-label">{`Trades : ${(payload[0].payload.transactions).toFixed(0)}`}</div>
              </div>
            );
        }
        return null;
    }

    return (
        <div className="card"
          style={{
              overflow: "auto",
              resize: "both",
              marginTop: "30px"
          }}>
            <div className="card-header">
                <div className="container d-inline-flex justify-content-between">
                    <div className="align-self-start">
                        Profit/Loss Weekdays
                    </div>
                    <FontAwesomeIcon className="dragHandle" variant="secondary" icon={faGripHorizontal} style={{ fontSize: "1.1rem", margin: "5px 10px 0px" }} />
                    <div></div>
                </div>
            </div>
            <div className="card-body"
            style={{
                overflow: "auto",
                height: "435px"
            }}>
                <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                      data={data}
                      margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5
                      }}
                    >
                      <CartesianGrid strokeOpacity={0.2} strokeDasharray="3 3"/>
                      <XAxis dataKey="weekDay" stroke="#c8c6c6" />
                      <YAxis tickFormatter={dollarFormatter} stroke="#c8c6c6" tick={{fontSize: 12}} width={40}/>
                      <Tooltip content={<CustomTooltip />} cursor={false} />
                      <Legend verticalAlign="top" wrapperStyle={{ lineHeight: "40px" }} />
                      <ReferenceLine y={0} stroke="#000" />
                      <Bar 
                      animationDuration={2000}
                      dataKey="pnl"
                      name="P/L day of the week"
                      fill="#8884d8"
                      >
                        {data.map((entry, index) => (
                            <Cell key={index} fill={data[index].pnl < 0 ? "#fc5661" : "#0acf97"} />
                        ))}
                      </Bar>
                    </BarChart>
                </ResponsiveContainer>
            </div>
        </div>
    )
}
