import { useState, useEffect, useRef } from 'react'

const WEEK_DAYS = {
    "0": "Sunday",
    "1": "Monday",
    "2": "Tuesday",
    "3": "Wednesday",
    "4": "Thursday",
    "5": "Friday",
    "6": "Saturday",
}

const ALL_MONTHS = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
]

export default function useCalculateTrades(trades) {
    // console.log(trades)

    let pnlPerWeekDay = []
    let pnlPerDay = []
    let pnlPerMonth = []
    let pnlPerYear = []
    let totalPnL = 0

    let totalFees = 0

    let topRunner = []
    let bottomRunner = []

    let timeStats = {}

    let totalWinTrade = 0
    let totalLossTrade = 0
    let totalScratchTrade = 0

    let totalWinAmount = 0
    let totalLossAmount = 0

    let largestGain = 0
    let largestLoss = 0

    let totalHoldTime = 0
    let totalWinHoldTime = 0
    let totalLossHoldTime = 0
    let totalScratchHoldTime = 0

    let avgHoldTime = 0 // derived
    let avgWinHoldTime = 0 // derived
    let avgLossHoldTime = 0 // derived
    let avgScratchHoldTime = 0 // derived

    let totalWinTradesVolume = 0
    let totalLossTradesVolume = 0

    let totalDays = 0
    let totalTrades = 0
    let totalVolume = 0 // totalVolume is considered as buy + sell

    let avgTradeVolume = 0 //derived
    let avgPerSharePL = 0 // derived
    let avgPerTradePL = 0 // derived

    const [returnObject, setReturnObject] = useState({
        pnlPerWeekDay,
        pnlPerDay,
        pnlPerMonth,
        pnlPerYear,
        totalPnL,
        totalFees,
        topRunner,
        bottomRunner,
        timeStats,
        totalWinTrade,
        totalLossTrade,
        totalScratchTrade,
        totalWinAmount,
        totalLossAmount,
        largestGain,
        largestLoss,
        avgHoldTime,
        avgWinHoldTime,
        avgLossHoldTime,
        totalWinTradesVolume,
        totalLossTradesVolume,
        totalDays,
        totalTrades,
        totalVolume,
        avgTradeVolume,
        avgPerSharePL,
        avgPerTradePL
    })

    useEffect(() => {
        try {
            const setTrades = (async () => {
                if (trades?.data?.calculated == 1) {
                    setReturnObject(await makeBrowserCompatible(trades))
                } else {
                    setReturnObject(await calculateOnBrowser(trades))
                }
            })
            setTrades()
        } catch (error) {
            console.log("Failed to calculate transactions", error)
        }
    }, [trades])

    function makeBrowserCompatible(trades) {
        return new Promise(resolve => {
            if (trades?.data?.userStats?.length > 0) {
                let pnlByWeekDays = {}
                let pnlByMonths = {}
                let pnlByYear = {}
                let tempTimeStats = {}
                let allClosedTrades = {}
                let userStats = trades?.data?.userStats
                for (let eachDayStats of userStats) {
                    totalDays += eachDayStats?.totalDays
                    totalPnL += eachDayStats?.totalPnL
                    totalFees += eachDayStats?.totalFees
                    totalHoldTime += eachDayStats?.totalHoldTime
                    totalLossAmount += eachDayStats?.totalLossAmount
                    totalLossHoldTime += eachDayStats?.totalLossHoldTime
                    totalLossTrade += eachDayStats?.totalLossTrade
                    totalLossTradesVolume += eachDayStats?.totalLossTradesVolume
                    totalScratchHoldTime += eachDayStats?.totalScratchHoldTime
                    totalScratchTrade += eachDayStats?.totalScratchTrade
                    totalTrades += eachDayStats?.totalTrades
                    totalVolume += eachDayStats?.totalVolume
                    totalWinAmount += eachDayStats?.totalWinAmount
                    totalWinHoldTime += eachDayStats?.totalWinHoldTime
                    totalWinTrade += eachDayStats?.totalWinTrade
                    totalWinTradesVolume += eachDayStats?.totalWinTradesVolume

                    largestGain = (largestGain > eachDayStats?.largestGain) ? largestGain : eachDayStats?.largestGain
                    largestLoss = (largestLoss < eachDayStats?.largestLoss) ? largestLoss : eachDayStats?.largestLoss

                    if (eachDayStats?.pnlPerDay?.[0]) {
                        // for everyday PnL calculation
                        if (eachDayStats?.pnlPerDay?.[0]) eachDayStats.pnlPerDay[0]["cumulativePnL"] = totalPnL
                        pnlPerDay.push(eachDayStats?.pnlPerDay?.[0])

                        // for weekly each day cumulative calculation
                        if (pnlByWeekDays[eachDayStats?.pnlPerDay?.[0]?.weekDay]) {
                            pnlByWeekDays[eachDayStats?.pnlPerDay?.[0]?.weekDay]["pnl"] += eachDayStats?.pnlPerDay?.[0]?.["pnl"]
                            pnlByWeekDays[eachDayStats?.pnlPerDay?.[0]?.weekDay]["volume"] += eachDayStats?.pnlPerDay?.[0]?.["volume"]
                            pnlByWeekDays[eachDayStats?.pnlPerDay?.[0]?.weekDay]["transactions"] += eachDayStats?.pnlPerDay?.[0]?.["transactions"]
                        } else {
                            pnlByWeekDays[eachDayStats?.pnlPerDay?.[0]?.weekDay] = JSON.parse(JSON.stringify(eachDayStats?.pnlPerDay?.[0]))
                        }

                        // for month each day cumulative calculation
                        if (pnlByMonths[eachDayStats?.pnlPerDay?.[0]?.month]) {
                            pnlByMonths[eachDayStats?.pnlPerDay?.[0]?.month]["pnl"] += eachDayStats?.pnlPerDay?.[0]?.["pnl"]
                            pnlByMonths[eachDayStats?.pnlPerDay?.[0]?.month]["volume"] += eachDayStats?.pnlPerDay?.[0]?.["volume"]
                            pnlByMonths[eachDayStats?.pnlPerDay?.[0]?.month]["transactions"] += eachDayStats?.pnlPerDay?.[0]?.["transactions"]
                        } else {
                            pnlByMonths[eachDayStats?.pnlPerDay?.[0]?.month] = JSON.parse(JSON.stringify(eachDayStats?.pnlPerDay?.[0]))
                        }

                        // for weekly each day cumulative calculation
                        if (pnlByYear[eachDayStats?.pnlPerDay?.[0]?.year]) {
                            pnlByYear[eachDayStats?.pnlPerDay?.[0]?.year]["pnl"] += eachDayStats?.pnlPerDay?.[0]?.["pnl"]
                            pnlByYear[eachDayStats?.pnlPerDay?.[0]?.year]["volume"] += eachDayStats?.pnlPerDay?.[0]?.["volume"]
                            pnlByYear[eachDayStats?.pnlPerDay?.[0]?.year]["transactions"] += eachDayStats?.pnlPerDay?.[0]?.["transactions"]
                        } else {
                            pnlByYear[eachDayStats?.pnlPerDay?.[0]?.year] = JSON.parse(JSON.stringify(eachDayStats?.pnlPerDay?.[0]))
                        }
                    }

                    // for timestats calculation
                    for (let eachTimeStat of eachDayStats.timeStats) {
                        if (tempTimeStats[eachTimeStat?.hour]) {
                            tempTimeStats[eachTimeStat?.hour]["hour_pnl"] += eachTimeStat?.["hour_pnl"]
                            tempTimeStats[eachTimeStat?.hour]["hourly_transactions"] += eachTimeStat?.["hourly_transactions"]
                        } else {
                            tempTimeStats[eachTimeStat?.hour] = eachTimeStat
                        }
                    }

                    for (let closedTrades of eachDayStats.closedTrades) {
                        if (allClosedTrades[closedTrades?.ticker]) {
                            allClosedTrades[closedTrades?.ticker]["total_trades"] += closedTrades["total_trades"]
                            allClosedTrades[closedTrades?.ticker]["trades_pnl"] += closedTrades["trades_pnl"]
                            allClosedTrades[closedTrades?.ticker]["trades_qty"] += closedTrades["trades_qty"]
                        } else {
                            allClosedTrades[closedTrades?.ticker] = closedTrades
                        }
                    }
                    // debugger
                }
                // debugger

                // set in a array format for each day of a week stats
                for (let weekOfTheDay in WEEK_DAYS) {
                    if (pnlByWeekDays[WEEK_DAYS[weekOfTheDay]]) {
                        pnlPerWeekDay.push({
                            weekDay: WEEK_DAYS[weekOfTheDay],
                            ...pnlByWeekDays[WEEK_DAYS[weekOfTheDay]]
                        })
                    }
                }

                // set in a array format by month
                for (let monthName of ALL_MONTHS) {
                    if (pnlByMonths[monthName]) {
                        pnlPerMonth.push({
                            month: monthName,
                            ...pnlByMonths[pnlByMonths]
                        })
                    }
                }

                let sortedYear = Object.keys(pnlByYear).sort()
                for (let eachYear of sortedYear) {
                    pnlPerYear.push({
                        year: eachYear,
                        ...pnlByYear[eachYear]
                    })
                }

                timeStats = []
                for (let eachHourStats in tempTimeStats) {
                    timeStats.push(tempTimeStats[eachHourStats])
                }


                let sortedTradesByPnL = []
                for (let eachClosedTrades in allClosedTrades) {
                    sortedTradesByPnL.push(allClosedTrades[eachClosedTrades])
                }
                sortedTradesByPnL.sort((a, b) => {
                    return b.trades_pnl - a.trades_pnl
                })

                topRunner = sortedTradesByPnL.slice(0, 10)
                topRunner = topRunner.filter((trade => {
                    return trade.trades_pnl > 0
                }))
                bottomRunner = sortedTradesByPnL.slice(-10).reverse()
                bottomRunner = bottomRunner.filter((trade => {
                    return trade.trades_pnl < 0
                }))

                avgPerSharePL = totalPnL / (totalVolume / 2)
                avgPerTradePL = totalPnL / totalTrades
                avgTradeVolume = (totalVolume / totalTrades) / 2 // this is only for closed trade, totalVolume is considered as buy + sell (so we have to divide it by 2)

                avgHoldTime = totalHoldTime / totalTrades
                avgWinHoldTime = totalWinHoldTime / totalWinTrade
                avgLossHoldTime = totalLossHoldTime / totalLossTrade
                avgScratchHoldTime = totalScratchHoldTime / totalScratchTrade


                // debugger
            }
            return resolve({
                pnlPerWeekDay,
                pnlPerDay,
                pnlPerMonth,
                pnlPerYear,
                totalPnL,
                totalFees,
                topRunner,
                bottomRunner,
                timeStats,
                totalWinTrade,
                totalLossTrade,
                totalScratchTrade,
                totalWinAmount,
                totalLossAmount,
                largestGain,
                largestLoss,
                avgHoldTime,
                avgWinHoldTime,
                avgLossHoldTime,
                totalWinTradesVolume,
                totalLossTradesVolume,
                totalDays,
                totalTrades,
                totalVolume,
                avgTradeVolume,
                avgPerSharePL,
                avgPerTradePL
            })
        })
    }

    function calculateOnBrowser(trades) {
        return new Promise(resolve => {
            let pnlByDay = {}
            let pnlByWeekDays = {}
            let pnlByMonths = {}
            let pnlByYear = {}
            if (trades && trades.closedTrades && trades.closedTrades.length > 0) {
                // console.log(trades.closedTrades)
                let closedTrades = trades.closedTrades
                for (let eachTickerIndex = 0; eachTickerIndex < closedTrades.length; eachTickerIndex++) {
                    let eachTickerTradeInfo = closedTrades[eachTickerIndex].trades
                    for (let eachTrade = 0; eachTrade < eachTickerTradeInfo.length; eachTrade++) {

                        //----- Calculate PnL on hourly basis
                        let tradeHour = getESTHour(eachTickerTradeInfo[eachTrade].closing_date)
                        let tradeMin = getHalfHourESTMinute(eachTickerTradeInfo[eachTrade].closing_date)
                        let uniqTradeTime = parseInt(`${tradeHour}${tradeMin == 0 ? '00' : tradeMin}`)
                        let tooltipDisplay = tradeMin == 0 ? `${tradeHour}:00 to ${tradeHour}:30` : `${tradeHour}:30 to ${tradeHour + 1}:00`
                        if (timeStats[uniqTradeTime]) {
                            // timeStats[uniqTradeTime] = {
                            //     hour_pnl: timeStats[uniqTradeTime]['hour_pnl'] + eachTickerTradeInfo[eachTrade].transactions_pnl,
                            //     hourly_transactions: timeStats[uniqTradeTime]['hourly_transactions'] + 1,
                            //     hour: uniqTradeTime,
                            //     hour_display: `${tradeHour}:${tradeMin == 0 ? '00' : tradeMin}`,
                            //     tooltip_display: tooltipDisplay
                            // }

                            timeStats[uniqTradeTime]['hour_pnl'] += eachTickerTradeInfo[eachTrade].transactions_pnl
                            timeStats[uniqTradeTime]['hourly_transactions'] += 1
                        } else {
                            timeStats[uniqTradeTime] = {
                                hour: uniqTradeTime,
                                tooltip_display: tooltipDisplay,
                                hour_display: `${tradeHour}:${tradeMin == 0 ? '00' : tradeMin}`,
                                hour_pnl: eachTickerTradeInfo[eachTrade].transactions_pnl,
                                hourly_transactions: 1
                            }
                        }
                        //----- Calculate PnL on hourly basis ----- DONE


                        //----- Init Daily PnL Info
                        let dateKey = getDateKey(eachTickerTradeInfo[eachTrade].closing_date, eachTickerTradeInfo[eachTrade].transactions_tz)
                        if (!pnlByDay[dateKey]) {
                            pnlByDay[dateKey] = {
                                pnl: 0,
                                volume: 0,
                                duration: 0,
                                cumulativePnL: 0,
                                transactions: 0
                            }
                        }

                        //----- Init WEEKDAY PnL Info
                        let dayOfTheWeek = WEEK_DAYS[getDay(eachTickerTradeInfo[eachTrade].closing_date, eachTickerTradeInfo[eachTrade].transactions_tz)] // get weekday
                        if (!pnlByWeekDays[dayOfTheWeek]) {
                            pnlByWeekDays[dayOfTheWeek] = {
                                pnl: 0,
                                volume: 0,
                                duration: 0,
                                cumulativePnL: 0,
                                transactions: 0
                            }
                        }

                        //----- Init MONTH PnL Info
                        let month = getMonth(eachTickerTradeInfo[eachTrade].closing_date, eachTickerTradeInfo[eachTrade].transactions_tz) // get month name
                        if (!pnlByMonths[month]) {
                            pnlByMonths[month] = {
                                pnl: 0,
                                volume: 0,
                                duration: 0,
                                transactions: 0
                            }
                        }

                        //----- Init YEAR PnL Info
                        let year = getYear(eachTickerTradeInfo[eachTrade].closing_date, eachTickerTradeInfo[eachTrade].transactions_tz) // get year
                        if (!pnlByYear[year]) {
                            pnlByYear[year] = {
                                pnl: 0,
                                volume: 0,
                                duration: 0,
                                transactions: 0
                            }
                        }


                        // calculate daily PnL
                        pnlByDay[dateKey]['pnl'] += eachTickerTradeInfo[eachTrade].transactions_pnl
                        pnlByDay[dateKey]['duration'] += eachTickerTradeInfo[eachTrade].trades_duration
                        pnlByDay[dateKey]['volume'] += eachTickerTradeInfo[eachTrade].transactions_qty
                        pnlByDay[dateKey]['transactions'] += eachTickerTradeInfo[eachTrade].transactions.length

                        let individualTransactions = eachTickerTradeInfo[eachTrade].transactions
                        for (let individualTransaction of individualTransactions) {
                            if (individualTransaction.total_fees) totalFees += individualTransaction.total_fees
                        }

                        // calculate by weekdays
                        pnlByWeekDays[dayOfTheWeek]['pnl'] += eachTickerTradeInfo[eachTrade].transactions_pnl
                        pnlByWeekDays[dayOfTheWeek]['duration'] += eachTickerTradeInfo[eachTrade].trades_duration
                        pnlByWeekDays[dayOfTheWeek]['volume'] += eachTickerTradeInfo[eachTrade].transactions_qty
                        pnlByWeekDays[dayOfTheWeek]['transactions'] += eachTickerTradeInfo[eachTrade].transactions.length

                        // calculate by month
                        pnlByMonths[month]['pnl'] += eachTickerTradeInfo[eachTrade].transactions_pnl
                        pnlByMonths[month]['duration'] += eachTickerTradeInfo[eachTrade].trades_duration
                        pnlByMonths[month]['volume'] += eachTickerTradeInfo[eachTrade].transactions_qty
                        pnlByMonths[month]['transactions'] += eachTickerTradeInfo[eachTrade].transactions.length

                        // calculate by year
                        pnlByYear[year]['pnl'] += eachTickerTradeInfo[eachTrade].transactions_pnl
                        pnlByYear[year]['duration'] += eachTickerTradeInfo[eachTrade].trades_duration
                        pnlByYear[year]['volume'] += eachTickerTradeInfo[eachTrade].transactions_qty
                        pnlByYear[year]['transactions'] += eachTickerTradeInfo[eachTrade].transactions.length


                        // find largest gainers and losers
                        largestGain = largestGain < eachTickerTradeInfo[eachTrade].transactions_pnl ? eachTickerTradeInfo[eachTrade].transactions_pnl : largestGain
                        largestLoss = largestLoss > eachTickerTradeInfo[eachTrade].transactions_pnl ? eachTickerTradeInfo[eachTrade].transactions_pnl : largestLoss

                        totalTrades++
                        totalVolume += eachTickerTradeInfo[eachTrade].transactions_qty

                        totalHoldTime += eachTickerTradeInfo[eachTrade].trades_duration
                        if (eachTickerTradeInfo[eachTrade].transactions_pnl > 0) {
                            totalWinTrade++
                            totalWinTradesVolume += eachTickerTradeInfo[eachTrade].transactions_qty
                            totalWinAmount += eachTickerTradeInfo[eachTrade].transactions_pnl
                            totalWinHoldTime += eachTickerTradeInfo[eachTrade].trades_duration
                        }
                        else if (eachTickerTradeInfo[eachTrade].transactions_pnl < 0) {
                            totalLossTrade++
                            totalLossTradesVolume += eachTickerTradeInfo[eachTrade].transactions_qty
                            totalLossAmount += eachTickerTradeInfo[eachTrade].transactions_pnl
                            totalLossHoldTime += eachTickerTradeInfo[eachTrade].trades_duration
                        }
                        else {
                            totalScratchTrade++
                            totalScratchHoldTime += eachTickerTradeInfo[eachTrade].trades_duration
                        }
                    }
                }
            }

            // set in a array format
            for (let date in pnlByDay) {
                totalPnL += pnlByDay[date].pnl
                pnlPerDay.push({
                    date,
                    ...pnlByDay[date]
                })
            }

            // set in a array format for each day of a week stats
            for (let weekOfTheDay in WEEK_DAYS) {
                if (pnlByWeekDays[WEEK_DAYS[weekOfTheDay]]) {
                    pnlPerWeekDay.push({
                        weekDay: WEEK_DAYS[weekOfTheDay],
                        ...pnlByWeekDays[WEEK_DAYS[weekOfTheDay]]
                    })
                }
            }

            // set in a array format by month
            for (let monthName of ALL_MONTHS) {
                if (pnlByMonths[monthName]) {
                    pnlPerMonth.push({
                        month: monthName,
                        ...pnlByMonths[pnlByMonths]
                    })
                }
            }

            let sortedYear = Object.keys(pnlByYear).sort()
            for (let eachYear of sortedYear) {
                pnlPerYear.push({
                    year: eachYear,
                    ...pnlByYear[eachYear]
                })
            }

            // sort all the days in the ascending order
            pnlPerDay.sort(function compare(a, b) {
                a = a.date.toString().split("-")
                b = b.date.toString().split("-")
                var dateA = new Date(a[2], a[0] - 1, a[1]);
                var dateB = new Date(b[2], b[0] - 1, b[1]);
                return dateA - dateB;
            });

            // find cumulative P/L
            let cumulativePnL = 0
            for (let eachDayPnL in pnlPerDay) {
                cumulativePnL += pnlPerDay[eachDayPnL]['pnl']
                pnlPerDay[eachDayPnL]['cumulativePnL'] = cumulativePnL
                totalDays++
            }

            // set hourly calculation stats
            let tempTimeStats = JSON.parse(JSON.stringify(timeStats))
            timeStats = []

            for (let eachHourStats in tempTimeStats) {
                timeStats.push(tempTimeStats[eachHourStats])
            }

            timeStats.sort((a, b) => {
                return a.hour - b.hour
            })

            let topRunnerTemp = JSON.parse(JSON.stringify(trades.closedTrades))
            let bottomRunnerTemp = JSON.parse(JSON.stringify(trades.closedTrades))

            topRunnerTemp.sort((a, b) => {
                return b.trades_pnl - a.trades_pnl
            })

            bottomRunnerTemp.sort((a, b) => {
                return a.trades_pnl - b.trades_pnl
            })

            for (let runnerIndex = 0; runnerIndex < 10; runnerIndex++) {
                if (topRunnerTemp[runnerIndex] && topRunnerTemp[runnerIndex].trades_pnl > 0) {
                    topRunner.push(topRunnerTemp[runnerIndex])
                } else {
                    break
                }
            }

            for (let runnerIndex = 0; runnerIndex < 10; runnerIndex++) {
                if (bottomRunnerTemp[runnerIndex] && bottomRunnerTemp[runnerIndex].trades_pnl < 0) {
                    bottomRunner.push(bottomRunnerTemp[runnerIndex])
                } else {
                    break
                }
            }

            avgPerSharePL = totalPnL / (totalVolume / 2)
            avgPerTradePL = totalPnL / totalTrades
            avgTradeVolume = (totalVolume / totalTrades) / 2 // this is only for closed trade, totalVolume is considered as buy + sell (so we have to divide it by 2)

            avgHoldTime = totalHoldTime / totalTrades
            avgWinHoldTime = totalWinHoldTime / totalWinTrade
            avgLossHoldTime = totalLossHoldTime / totalLossTrade
            avgScratchHoldTime = totalScratchHoldTime / totalScratchTrade

            return resolve({
                pnlPerWeekDay,
                pnlPerDay,
                pnlPerMonth,
                pnlPerYear,
                totalPnL,
                totalFees,
                topRunner,
                bottomRunner,
                timeStats,
                totalWinTrade,
                totalLossTrade,
                totalScratchTrade,
                totalWinAmount,
                totalLossAmount,
                largestGain,
                largestLoss,
                avgHoldTime,
                avgWinHoldTime,
                avgLossHoldTime,
                totalWinTradesVolume,
                totalLossTradesVolume,
                totalDays,
                totalTrades,
                totalVolume,
                avgTradeVolume,
                avgPerSharePL,
                avgPerTradePL
            })
        })
    }

    const getDateKey = ((date, tz) => {
        date = new Date(new Date(date).toLocaleString("en-US", { timeZone: tz }))
        return `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`
    })

    const getDay = ((date, tz) => {
        date = new Date(new Date(date).toLocaleString("en-US", { timeZone: tz }))
        return date.getDay()
    })

    const getMonth = ((date, tz) => {
        return new Date(date).toLocaleString("en-US", { timeZone: tz, month: 'long' })
    })

    const getYear = ((date, tz) => {
        return new Date(date).toLocaleString("en-US", { timeZone: tz, year: 'numeric', })
    })

    const getESTHour = ((date) => {
        date = new Date(new Date(date).toLocaleString("en-US", { timeZone: `America/New_York` }))
        return date.getHours()
    })

    const getESTMinute = ((date) => {
        date = new Date(new Date(date).toLocaleString("en-US", { timeZone: `America/New_York` }))
        return date.getMinutes()
    })

    const getHalfHourESTMinute = ((date) => {
        let minute = getESTMinute(date)
        return minute < 30 ? 0 : 30
    })

    return (returnObject)
}
