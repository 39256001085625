import { useState, useEffect, useRef, useContext } from 'react'

import helper from './helper'

export default function useNews() {
    const [newsInfo, setNewsInfo] = useState()


    /**
     * Get the hot news for the first time using API
     */
    useEffect(() => {
        setNewsInfo([])
        const getHotNews = async () => {
            const hotNewsInfo = await fetchHotNews()
            setNewsInfo(hotNewsInfo)
        }
        getHotNews()
    }, [])

    /**
     * use socket connection to listen live hot news
     */
    useEffect(() => {
        const socket = helper.getSocketConn()
        socket.on('hotNews', msg => {
            if (msg && msg.data) {
                setNewsInfo(msg.data);
            }
        });
    }, [])

    /**
     * API to fetch hot news data
     */
    const fetchHotNews = async () => {
        try {
            let hotNewsUrl = `${helper.apiUrl}/hotnews`
            const hotNews = await fetch(hotNewsUrl)
            const hotNewsAPI = await hotNews.json()
            return hotNewsAPI.data
        } catch (error) {
            console.log(`Failed to fetch hot news info`)
            console.log(error)
            return []
        }
    }
    return newsInfo
}