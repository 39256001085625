import React, { useEffect, useState, useRef } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog, faQuestionCircle, faGripHorizontal } from '@fortawesome/free-solid-svg-icons'
import Draggable from 'react-draggable';

import { numberConversion, timeToTz, getTimeFromLong, getTimeFromLongInDate } from '../utils/jshelper'
import useEarningCalendar from '../controllers/useEarningCalendar'


function EarningCalendar({ title, newsInfo, setOnClick }) {
    let earningCalendarInfo = useEarningCalendar()
    
    const nodeRef = useRef(null);
    
    const tickerRegex = /^([^\(]+)/
    function setOnClickEvent(e) {
        let tickerName = e.target.textContent
        if (tickerName) {
            let regexResult = tickerRegex.exec(tickerName)
            if (regexResult && regexResult[0]) {
                setOnClick(regexResult[0])
            }
        }
    }

    // get news table
    function getEarningCalendar() {
        let earningCalendarTable = []
        if (earningCalendarInfo) {
            for (var i = 0; i < earningCalendarInfo.length; i++) {
                earningCalendarTable.push(<tr key={i}>
                    <td style={{ color: "#71b6f9" }} className="text-nowrap">{getTimeFromLongInDate(timeToTz(new Date(earningCalendarInfo[i].date), "Etc/Greenwich"))}</td>
                    <td 
                        style={{ fontSize: "14px", cursor: "pointer" }}
                        className="code-font"
                        onClick={e => setOnClickEvent(e)}>
                        {earningCalendarInfo[i].symbol}
                    </td>
                    <td style={{ textTransform: "uppercase" }} className="text-nowrap">{earningCalendarInfo[i].hour ? earningCalendarInfo[i].hour : '-'}</td>
                    <td className="text-nowrap">{earningCalendarInfo[i].epsEstimate ? earningCalendarInfo[i].epsEstimate : '-'}</td>
                    <td className="text-nowrap">{earningCalendarInfo[i].revenueEstimate ? numberConversion(earningCalendarInfo[i].revenueEstimate) : '-'}</td>
                    <td className="text-nowrap">{earningCalendarInfo[i].marketCap ? numberConversion(earningCalendarInfo[i].marketCap*1000000) : '-'}</td>
                    <td className="text-nowrap">{earningCalendarInfo[i].peRatio ? earningCalendarInfo[i].peRatio : '-'}</td>
                </tr>
                );
            }
        }
        return earningCalendarTable
    }

    return (
        <div className="card"
            ref={nodeRef}
            style={{
                overflow: "auto",
                resize: "both"
            }}>
            <div className="card-header">
                <div className="container d-inline-flex justify-content-between">
                    <div className="align-self-start">
                        {title}
                    </div>
                    <FontAwesomeIcon className="dragHandle" variant="secondary" icon={faGripHorizontal} style={{ fontSize: "1.1rem", margin: "5px 10px 0px" }} />
                    <div></div>
                </div>
            </div>
            <div className="card-body">
                <div className="row mb-1">
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div style={{
                            overflow: "auto",
                            minHeight: "100px",
                            height: "440px"
                        }}>
                            <table className="table tableFixHead table-condensed">
                                <thead className="table-header">
                                    <tr>
                                        <th>Date</th>
                                        <th>Symbol</th>
                                        <th>Hour</th>
                                        <th>EPS Estimate</th>
                                        <th>Revenue Estimate</th>
                                        <th>Market Cap</th>
                                        <th>P/E</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {getEarningCalendar()}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EarningCalendar