import React, { useEffect } from "react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGripHorizontal } from '@fortawesome/free-solid-svg-icons'


export default function EconomicCalendar({ title }) {

    useEffect(() => {
        const script = document.createElement('script');

        script.src = "https://s3.tradingview.com/external-embedding/embed-widget-events.js";
        script.async = true;
        script.innerHTML = JSON.stringify({
            "colorTheme": "dark",
            "isTransparent": false,
            "width": "100%",
            "height": "460",
            "locale": "en",
            "importanceFilter": "0,1",
            "currencyFilter": "USD"
        })

        document.getElementsByClassName("tradingview-widget-container-economic")[0].appendChild(script);
    }, []);
    return (
        <div style={{
            overflow: "auto",
            minHeight: "100px",
            height: "550px"
        }}>

            <div className="card"
                style={{
                    overflow: "auto",
                    resize: "both"
                }}>
                <div className="card-header">
                    <div className="container d-inline-flex justify-content-between">
                        <div className="align-self-start">
                            {title}
                        </div>
                        <FontAwesomeIcon className="dragHandle" variant="secondary" icon={faGripHorizontal} style={{ fontSize: "1.1rem", margin: "5px 10px 0px" }} />
                        <div></div>
                    </div>
                </div>
                <div className="card-body pt-0">
                    <div className="tradingview-widget-container-economic">
                    </div>
                </div>
            </div>
        </div>
    );
}
