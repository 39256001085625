import React from 'react'
import { Link } from "react-router-dom";


export default function homeHeader() {
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-sm-12 d-flex justify-content-center align-items-center flex-wrap" style={{ padding: "10px 40px 10px" }}>
                    <div>
                        <Link className="d-flex align-items-center" to="/" style={{ textDecoration: "none", color: "#FFFFFF" }}>
                            <img className="me-3" alt="Momoscreener logo" src="/logo/ico/logo-64.ico" height="64" width="64" alt="logo" />
                            <h2 className="pt-2">
                                Momo Screener
                            </h2>
                        </Link>
                    </div>

                    <div className="home-header-button-margin">
                        <Link className="header-button" to="/">Home</Link>
                        <Link className="header-button" to="/scanner">Scanner</Link>
                        <Link className="header-button" to="/daytrade">DayTrade</Link>
                        <Link className="header-button" to="/charts">Charts</Link>
                        <Link className="header-button" to="/trades">Trades</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}