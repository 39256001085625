import DayTrade from './DayTrade'

import { Helmet } from 'react-helmet-async';

import Header from '../Header';

function DayTradeRoute() {
    return (
        <>
            <Helmet>
                <meta name="description" content="MomoScreener finds the momentum stock of the day with the latest news in the real-time. High of the day, Stock screener for day traders, Top gappers, Free stock screeners, Free stock scanner, Live news, Find hot stocks" />
                <meta name="keywords" content="High of the day, Stock screener for day traders, Find hot stocks, Penny stocks, Real-time stock scanners, Stocks with news, Top gappers, Free stock screeners, Free stock scanner, Live news, Filter stocks" />

                <meta name="twitter:title" value="Momo Screener - Find momentum stocks for the day trading" />
                <meta name="twitter:description" content="MomoScreener finds the momentum stock of the day with the latest news in the real-time. High of the day, Stock screener for day traders, Top gappers, Free stock screeners, Free stock scanner, Live news, Find hot stocks" />

                <meta name="og:title" property="og:title" content="Momo Screener - Find momentum stocks for the day trading" />
                <meta name="og:description" property="og:description" content="MomoScreener finds the momentum stock of the day with the latest news in the real-time. High of the day, Stock screener for day traders, Top gappers, Free stock screeners, Free stock scanner, Live news, Find hot stocks" />

                <title>Day Trade - MomoScreener</title>
            </Helmet>
            <Header />
            <DayTrade />
        </>
    )
}

export default DayTradeRoute
