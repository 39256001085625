export const numberConversion = (number) => {

    // Nine Zeroes for Billions
    return Math.abs(Number(number)) >= 1.0e+12

        ? (Math.abs(Number(number)) / 1.0e+12).toFixed(2) + "T"
        : Math.abs(Number(number)) >= 1.0e+9
            ? (Math.abs(Number(number)) / 1.0e+9).toFixed(2) + "B"
            // Six Zeroes for Millions 
            : Math.abs(Number(number)) >= 1.0e+6

                ? (Math.abs(Number(number)) / 1.0e+6).toFixed(2) + "M"
                // Three Zeroes for Thousands
                : Math.abs(Number(number)) >= 1.0e+3

                    ? (Math.abs(Number(number)) / 1.0e+3).toFixed(2) + "K"

                    : Math.abs(Number(number));

}

export const playSound = (soundFunc, canSound) => {
    try {
        // if (canSound) {
        let startPlayPromise = soundFunc.play();

        if (startPlayPromise !== undefined) {
            startPlayPromise.then(() => {
                // Start whatever you need to do only after playback
                // has begun.
            }).catch(error => {
                console.log(error)
                if (error.name === "NotAllowedError") {

                } else {
                    // Handle a load or playback error
                }
            });
        }
        // }
        // soundFunc.play()
    } catch (error) {
        console.log("Error in playing sound, user needs to interact with UI")
        console.log(error)
    }
}

export const greenYellowRed = (value, breakEvenValue = 4) => {
    value--;
    // console.log(breakEvenValue)
    // let breakEvenValue = 4

    var r, g, b;

    if (value < breakEvenValue) {
        // green to yellow
        r = Math.floor(255 * (value / breakEvenValue));
        g = 255;

    } else {
        // yellow to red
        r = 255;
        g = Math.floor(255 * ((breakEvenValue - value % breakEvenValue) / breakEvenValue));
    }
    b = 0;
    return r + "," + g + "," + b;
}

let getTimeTwoDigitFormat = number => {
    return (number < 10 ? '0' : '') + number
}
export { getTimeTwoDigitFormat }

export const getTimeFromLong = timeStamp => {
    var date = getTimeTwoDigitFormat((timeStamp.getMonth() + 1)) + '-' + getTimeTwoDigitFormat(timeStamp.getDate()) + '-' + getTimeTwoDigitFormat(timeStamp.getFullYear());
    var time = getTimeTwoDigitFormat(timeStamp.getHours()) + ":" + getTimeTwoDigitFormat(timeStamp.getMinutes()) + ":" + getTimeTwoDigitFormat(timeStamp.getSeconds());
    return `${date} ${time}`
}

export const getTimeFromLongInHrs = timeStamp => {
    var time = getTimeTwoDigitFormat(timeStamp.getHours()) + ":" + getTimeTwoDigitFormat(timeStamp.getMinutes()) + ":" + getTimeTwoDigitFormat(timeStamp.getSeconds());
    return `${time}`
}

export const getTimeFromLongInDate = timeStamp => {
    var date = getTimeTwoDigitFormat((timeStamp.getMonth() + 1)) + '-' + getTimeTwoDigitFormat(timeStamp.getDate()) + '-' + getTimeTwoDigitFormat(timeStamp.getFullYear());
    return `${date}`
}

export const timeToTz = (originalTime, timeZone) => {
    const zonedDate = new Date(new Date(originalTime).toLocaleString('en-US', { timeZone }));
    return zonedDate;
}