import { useState, useEffect, useRef, useContext } from 'react'

import helper from './helper'
let lastQuoteTime = new Date().getTime()
let intervalObj = ""

export default function useQuote(ticker) {
    const [quoteInfo, setQuoteInfo] = useState()

    /*useEffect(() => {
        if (intervalObj) {
            clearInterval(intervalObj)
        }
        intervalObj = setInterval(() => {
            let currentTime = new Date().getTime()
            if ((currentTime - lastQuoteTime) > 5000) {
                const socket = helper.getSocketConn()
                socket.emit('livequote', ticker.trim())
            }
        }, 5000)
        
        return(() => {
            clearInterval(intervalObj)
        })
    }, [ticker])*/

    /**
     * use socket connection to listen live quote info
     */
    useEffect(() => {
        const socket = helper.getSocketConn()
        socket.removeListener('livequote')
        socket.on('livequote', msg => {
            lastQuoteTime = new Date().getTime()
            if (msg && msg.data && msg.data[0] && msg.data[0].symbol.toUpperCase()  === ticker.toUpperCase() ) {
                setQuoteInfo(msg.data[0]);
            }
        });
        
        return (() => {
            const socket = helper.getSocketConn()
            socket.emit('stoplivequote')
            // socket.off('livequote')
        })
    }, [ticker])


    /**
     * use socket connection to send update ticker info
     */
    useEffect(() => {
        const socket = helper.getSocketConn()
        socket.emit('livequote', ticker.trim())
    }, [ticker])
    return quoteInfo
}