import { useMemo } from 'react'

export default function useDefaultData() {
    return useMemo(() => {
        return {
            sound: false,
            themes: [
                {
                    name: 'dark',
                    isLight: false
                },
                {
                    name: 'light',
                    isLight: true
                },
                {
                    name: 'monokai',
                    isLight: false
                }
            ]
        }
    }, [])
}