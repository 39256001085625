import React, { useState, useEffect, useRef } from 'react'
import AsyncSelect from 'react-select/async';
import Draggable from 'react-draggable';
// import './App.css';
import "../../public/root.css";
import "../../public/style.css";

import helper from '../../controllers/helper'
// import ReactGA from 'react-ga'

import { GlobalStateContextProvider } from '../../controllers/useContextReducer'

import Header from '../Header';
import Chart from './Chart'

function Charts() {

    const [topMovers, setTopMovers] = useState([])
    useEffect(() => {
        const fetchMomo = (async () => {
            let topMoversAPI = await fetch(`${helper.apiUrl}/momo`, { cache: "no-cache" });
            topMoversAPI = await topMoversAPI.json();
            if (topMoversAPI?.message?.length > 5) {
                setTopMovers(topMoversAPI.message.slice(0, 6))
            }
        })
        fetchMomo()
    }, [])

    return (
        <GlobalStateContextProvider>
            <div className="StockScreener">
                <Header />
                <div className="container-fluid">
                    <div className="row">
                        {
                            topMovers.map((stockInfo, index) => {
                                return <div className="col-md-12 col-lg-6 col-xl-6 col-xxl-4" key={index + 1}>
                                    <Chart
                                        providedTicker={stockInfo.symbol}
                                        uniqId={index + 1}
                                    />
                                </div>
                            })
                        }
                    </div>
                </div>
            </div>
        </GlobalStateContextProvider>
    );
}

export default Charts;
