import React from 'react'
import topbg from '../../public/assets/images/top/top-bg.webp'
import topafter from '../../public/assets/images/top/top-after.webp'
import scannerimg from '../../public/assets/images/top/scanner.webp'

export default function Top() {
    return (
        <section className="top-section bg_img" data-background={topbg} style={{ "backgroundImage": `url(${topbg})`, "paddingTop": "60px" }}>
            <div className="container" style={{ maxWidth: "725px", textAlign: "center" }}>
                <div className="d-flex flex-column justify-content-between">
                    <div className="d-flex flex-column justify-content-center align-items-center text-white">
                        <h1 className="title">Uncover Market Gems: Momentum, News, Analysis Combined</h1>
                        <p>Trading Insight at Your Fingertips: High Moving and Halted Stocks, Real-Time News, and Comprehensive Trading Analysis</p>
                        <div>
                            <a href="/scanner" className="scanner-button">Open free scanner</a>
                        </div>
                    </div>
                    <div data-aos="fade-down" data-aos-duration="800" className="align-self-end">
                        <img className="scanner_bg_img img-fluid" src={scannerimg} alt="scanner screenshot" />
                    </div>
                </div>
            </div>
            <div className="top_after_img_div">
                <img className="top_after_img" src={topafter} alt="top background overlap" />
            </div>
        </section>
    )
}