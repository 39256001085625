import { useState } from 'react'
import constactbg from '../../public/assets/images/contact/contact_bg.png'
import contactBottomShadeImg from '../../public/assets/images/contact/contact_bottom_shade.webp'
import helper from '../../controllers/helper'

export default function Contact(){
    const [showMessage, setShowMessage] = useState({ show: false, success: true, message: "Successfully submitted!" })
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [message, setMessage] = useState("")
    
    async function sendFeedback(e){
        try{
            e.preventDefault()
            setShowMessage({ show: false})
            
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ name, email, message })
            };
            let feedbackResponse = await fetch(`${helper.apiUrl}/feedback`, requestOptions);
            feedbackResponse = await feedbackResponse.json();
            if(feedbackResponse && feedbackResponse.error == 0){
                setShowMessage({ show: true, success: true, message: "Successfully submitted!" })
                setName("")
                setEmail("")
                setMessage("")
            } else {
                setShowMessage({ show: true, success: 0, message: feedbackResponse.message })
            }
        } catch (error){
            setShowMessage({ show: true, success: 0, message: error && error.message ? error.message : error})
        }
    }
    
    return(
        <section>
            <section className="top-section bg_img" data-background={ constactbg } style={{"backgroundImage": `url(${constactbg})`, "paddingTop": "60px", height: "400px"}}>
                <div className="top_after_img_div">
                    <img className="top_after_img" src={ contactBottomShadeImg } alt="contact background overlap"/>
                </div>
            </section>
            <section data-aos="zoom-in" data-aos-duration="500" style={{ marginTop: "-225px", position: "relative", zIndex: "3" }}>
                <div className="container">
                    <div className="row justify-content-center justify-content-lg-between">
                        <div className="offset-lg-3 col-lg-6 col-sm-12">
                            <div className="contact-wrapper">
                                <h4 className="title text-center mb-30">Question, Feedback or Suggestion</h4>
                                <form className="contact-form" id="contact_form_submit" onSubmit={sendFeedback}>
                                    <div className="form-group">
                                        <label htmlFor="name">Your Full Name</label>
                                        <input type="text" value={name} onChange={e => setName(e.target.value)} placeholder="Enter Your Full Name" id="name" required/>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="email">Your Email </label>
                                        <input type="email" value={email} onChange={e => setEmail(e.target.value)} placeholder="Enter Your Email " id="email" required/>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="message">Your Message </label>
                                        <textarea id="message" value={message} onChange={e => setMessage(e.target.value)} placeholder="Enter Your Message" required></textarea>
                                    </div>
                                    { showMessage && showMessage.show ?
                                    <div className="form-text formSubmitMessage">
                                        <p className={showMessage.success ? "text-success" : "text-danger"}>{ showMessage.message }</p>
                                    </div> : ""
                                    }
                                    <div className="form-group">
                                        <input type="submit" value="Send Message" />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </section>
    )
}