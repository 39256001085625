import React, { useEffect, useState, useRef } from 'react'
import AsyncSelect from 'react-select/async';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog, faQuestionCircle, faGripHorizontal } from '@fortawesome/free-solid-svg-icons'

import { numberConversion, getTimeTwoDigitFormat, getTimeFromLong } from '../utils/jshelper'
import helper from '../controllers/helper'


function QuoteBox({ ticker, setQuote, quoteInfo, setSelectedTicker, isFooterRequired = true }) {
    const [localTicker, setLocalTicker] = useState(ticker)
    const nodeRef = useRef(null);
    const [cardKey, setCardKey] = useState(0)

    useEffect(() => {
        setLocalTicker(ticker)

        // add ticker change effect on quote and chart boxes
        setCardKey(cardKey + 1)
    }, [ticker])

    function updateLocalTicker(e) {
        if (e.key === 'Enter') {
            let newTicker = e.target.value
            if (newTicker) {
                setSelectedTicker("")
                setQuote(newTicker)
            }
        }
    }

    // get news table
    function getNews() {
        let newsTable = []
        if (quoteInfo && quoteInfo.news) {
            for (var i = 0; i < quoteInfo.news.length; i++) {
                if (i + 1 < quoteInfo.news.length && quoteInfo.news[i].id == quoteInfo.news[i + 1].id) continue
                newsTable.push(<tr key={i}>
                    <td style={{ color: "#71b6f9" }} className="text-nowrap">{getTimeFromLong(new Date(quoteInfo.news[i].newsTimeInLong))}</td>
                    <td className="code-font">{quoteInfo.news[i].headline} ({quoteInfo.news[i].source}) {quoteInfo.news[i].isHot ? "HOT" : ""}</td>
                </tr>
                );
            }
        }
        return newsTable
    }

    // get current price info
    function getCurrentPrice() {
        let currentPrice = ""
        if (quoteInfo) {
            let change = ""
            let price = ""

            if (quoteInfo.live && quoteInfo.quote && (quoteInfo.live.quoteTime > quoteInfo.quote.quoteTime)) {
                change = Number.parseFloat((((quoteInfo.live.lastPrice - quoteInfo.quote.closePrice) / quoteInfo.quote.closePrice) * 100)).toFixed(2)
                price = Number.parseFloat(quoteInfo.live.lastPrice.toFixed(2))
            } else if (quoteInfo.quote) {
                change = Number.parseFloat((quoteInfo.quote.markPercentChange)).toFixed(2)
                price = Number.parseFloat(quoteInfo.quote.lastPrice.toFixed(2))
            }
            price = Number.parseFloat(quoteInfo?.stats?.price.toFixed(2))

            currentPrice += price ? price : ""
            currentPrice += change ? ` (${change}%)` : ""
        }
        return currentPrice
    }

    // get stats table
    function getStats1() {
        let stats = []
        if (quoteInfo && quoteInfo.quote) {
            let relativeVol = 0
            if (quoteInfo.quote.fundamental && quoteInfo.quote.fundamental.vol10DayAvg) {
                relativeVol = (quoteInfo.quote.totalVolume / quoteInfo.quote.fundamental.vol10DayAvg).toFixed(2)
            }
            let statsInfo = {
                "Day High": quoteInfo.quote.highPrice,
                "Day Low": quoteInfo.quote.lowPrice,
                "Volume": numberConversion(quoteInfo.quote.totalVolume),
                "Relative Volume": relativeVol,
                "Shortable": quoteInfo.quote.isShortable ? "Yes" : "No",
                "Status": quoteInfo.quote.securityStatus
            }
            let i = 0
            for (let stat in statsInfo) {
                stats.push(
                    <tr key={i}>
                        <td>{stat}</td>
                        <td className="text-end">{statsInfo[stat]}</td>
                    </tr>
                )
                i++
            }
        }
        return stats
    }


    // get stats table
    function getStats2() {
        let stats = []
        if (quoteInfo && quoteInfo.quote && quoteInfo.quote.fundamental) {
            let statsInfo = {
                "52 Week High": quoteInfo.quote.fundamental.high52,
                "52 Week Low": quoteInfo.quote.fundamental.low52,
                "Market Cap": numberConversion(quoteInfo.quote.fundamental.marketCap),
                // "Float": numberConversion(quoteInfo.quote.fundamental.marketCapFloat),
                "Float": numberConversion(quoteInfo?.stats.floatShares),
                "PE Ratio": quoteInfo.quote.peRatio,
                "EPS": quoteInfo.quote.fundamental.epsTTM
            }
            let i = 0
            for (let stat in statsInfo) {
                stats.push(
                    <tr key={i}>
                        <td>{stat}</td>
                        <td className="text-end">{statsInfo[stat]}</td>
                    </tr>
                )
                i++
            }
        }
        return stats
    }

    let controller = null;
    const promiseOptions = (inputValue) =>
        new Promise(async (resolve, reject) => {
            try {
                if (inputValue) {
                    if (controller) controller.abort();
                    controller = new AbortController();
                    let stockSuggestions = await fetch(`${helper.apiUrl}/stocklist?q=${inputValue}`, { signal: controller.signal });
                    controller = null;
                    stockSuggestions = await stockSuggestions.json();
                    let autocompleteList = []
                    if (stockSuggestions && stockSuggestions.length > 0) {
                        for (let stock of stockSuggestions) {
                            autocompleteList.push({
                                value: stock.symbol,
                                label: `${stock.symbol} - ${stock.description}`
                            })
                        }
                        return resolve(autocompleteList)
                    } else {
                        return resolve()
                    }
                } else {
                    return reject()
                }
            } catch (error) {

            }
        })

    const autoCompleteStyle = {
        container: (styles) => ({
            ...styles,
            maxWidth: "150px",
            minWidth: "100%",
        }),
        control: (styles) => ({
            ...styles,
            backgroundColor: '#2b2e4c',
            height: "45px",
            paddingLeft: "10px",
            textTransform: "uppercase",
            border: "none",
        }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                backgroundColor: isFocused ? "#d41459" : "#2b2e4c",
                cursor: "pointer",
                color: '#c8c6c6',
                textTransform: "uppercase",
                ':hover': {
                    ...styles[':hover'],
                    background: "#d41459"
                }
            }
        },
        menu: base => ({
            ...base,
            background: "#2b2e4c",
            color: '#c8c6c6',
            maxWidth: "500px",
            minWidth: "350px",
            fontSize: "12px"
        }),
        menuList: base => ({
            ...base,
            paddingTop: 0,
            paddingBottom: 0,
        }),
        singleValue: provided => ({
            ...provided,
            color: '#c8c6c6',
        }),
        input: provided => ({
            ...provided,
            color: '#c8c6c6',
            minWidth: "100%"
        }),
        placeholder: (styles) => ({
            ...styles,
            width: "100%"
        })
    }

    const asyncRef = useRef(null);
    useEffect(() => {
        document.addEventListener('keydown', logAnyKey);
        function logAnyKey(e) {
            if (e && e.key && e.keyCode) {
                if (e.keyCode >= 65 && e.keyCode <= 90) {
                    asyncRef.current?.focus();
                }
            }
        }
    }, [])


    return (
        <div className="card box-shadow"
            key={cardKey}
            ref={nodeRef}
            style={{
                overflow: "auto",
                resize: "both",
            }}>
            <div className="card-header">
                <div className="container d-inline-flex justify-content-between">
                    <div className="align-self-start">
                        Quote Info
                    </div>
                    <FontAwesomeIcon className="dragHandle" variant="secondary" icon={faGripHorizontal} style={{ fontSize: "1.1rem", margin: "5px 10px 0px" }} />
                    <div></div>
                </div>
            </div>
            <div className="card-body"
                style={{
                    overflow: "auto",
                    height: "435px",
                }}>
                <div className="row mb-1">
                    <div className="col-md-3 col-sm-6 d-flex justify-content-center justify-content-md-start align-items-center mb-2">
                        <AsyncSelect
                            cacheOptions
                            defaultOptions
                            loadOptions={promiseOptions}
                            ref={asyncRef}
                            styles={autoCompleteStyle}
                            value={{ value: localTicker, label: localTicker }}
                            // placeholder={localTicker}
                            onChange={e => {
                                setLocalTicker(e.value)
                                setQuote(e.value)
                            }}
                            noOptionsMessage={() => null}
                            components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                            inputId="uppercase"
                            classNamePrefix="react-select"
                        />


                        {/*<input type="text"
                            style={{ "width": "150px", "height": "45px", "textTransform": "uppercase" }}
                            value={localTicker}
                            onChange={e => setLocalTicker(e.target.value)}
                            onKeyDown={updateLocalTicker}
                            className="form-input"
                            placeholder="Symbol" />*/}
                    </div>
                    <div className="col-md-4 col-sm-6 text-center d-flex justify-content-center align-items-center mb-2">
                        {/* {quoteInfo && quoteInfo.live && quoteInfo.live.lastPrice ? quoteInfo.live.lastPrice : ""} */}
                        {getCurrentPrice()}<br />
                        <div style={{ marginLeft: "10px" }}><a href={"https://www.tradingview.com/chart?symbol=" + ticker} target="_blank">Chart</a></div>
                    </div>
                    <div className="col-md-5 col-sm-6 text-center d-flex justify-content-center align-items-center mb-2">
                        <div>
                            {quoteInfo && quoteInfo.quote && quoteInfo.quote.description ? quoteInfo.quote.description : ""}
                        </div>
                    </div>
                </div>
                <div className="row" style={{ "fontSize": "14px" }}>
                    <div className="col-md-6">
                        <table className="table table-sm">
                            <tbody>
                                {getStats1()}
                            </tbody>
                        </table>
                    </div>
                    <div className="col-md-6">
                        <table className="table table-sm">
                            <tbody>
                                {getStats2()}
                            </tbody>
                        </table>
                    </div>
                </div>
                <h5 className="card-title">News</h5>
                <div className="row">
                    <div className="col-md-12">
                        <table className="table table-bordered table-condensed">
                            <tbody>
                                {getNews()}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            {isFooterRequired &&
                <div className="card-footer text-muted">
                    Last updated: {getTimeFromLong(new Date())}
                </div>
            }
        </div>
    )
}

export default QuoteBox