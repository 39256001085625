import { useState, useEffect, useRef, useContext } from 'react'

import helper from './helper'

export default function useEarningCalendar(ticker) {
    const [earningCalendarData, setEarningCalendarData] = useState()

    useEffect(() => {
        const requestEarningCalendar = (async () => {
            let earningCalendar = await fetchEarningCalendar()
            if (earningCalendar && earningCalendar.length > 0) {
                setEarningCalendarData(earningCalendar)
            } else {
                setEarningCalendarData([])
            }
        })
        requestEarningCalendar()
    }, [])

    /**
     * API to fetch earning calendars
     */
    const fetchEarningCalendar = () => {
        return new Promise(async resolve => {
            try {
                let earningCalendarAPI = `${helper.apiUrl}/earningcalendar`
                let earningCalendar = await fetch(earningCalendarAPI)
                earningCalendar = await earningCalendar.json()
                return resolve(earningCalendar.data)
            } catch (error) {
                console.log(`Failed to fetch earning calendar info`)
                console.log(error)
                return resolve([])
            }
        })
    }
    return earningCalendarData
}