import { useState, useEffect } from 'react'
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";


export default function TradeFilter({ ticker, setTicker, startDate, setStartDate, endDate, setEndDate, setEndDateNumber, onFilter, onClear, onDeleteCount }) {

    return (
        <div className="row">
            <div className="col-12 col-lg-12 col-xl-8 d-flex justify-content-center" style={{ marginTop: "10px" }}>
                <div className="row">
                    <div className="col-4">
                        <input type="text"
                            style={{ maxWidth: "130px", fontSize: "0.9rem", height: "30px", textTransform: "uppercase", paddingLeft: "8px" }}
                            value={ticker}
                            onChange={e => setTicker(e.target.value)}
                            className="form-input"
                            placeholder="Ticker"
                        />
                    </div>

                    <div className="col-4" style={{ maxWidth: "170px", zIndex: 91 }}>
                        <DatePicker
                            className="form-input date-picker-form"
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            isClearable
                            placeholderText="Start date"
                        />
                    </div>

                    <div className="col-4" style={{ maxWidth: "170px", zIndex: 91 }}>
                        <DatePicker
                            className="form-input date-picker-form"
                            selected={endDate}
                            onChange={(date) => setEndDate(date)}
                            isClearable
                            placeholderText="End date"
                        />
                    </div>
                </div>
            </div>

            {/*<input type="date"
            required pattern="\d{4}-\d{2}-\d{2}"
            style={{ width: "170px", height: "35px", textTransform: "uppercase", marginLeft: "10px", fontSize: "14px" }}
            className="form-input"
            value={startDate}
            onChange={e => setStartDate(e.target.value)}
            placeholder="Start Date"
            />*/}

            {/*<input type="date"
            required pattern="\d{4}-\d{2}-\d{2}"
            style={{ width: "170px", height: "35px", textTransform: "uppercase", marginLeft: "10px", fontSize: "14px" }}
            className="form-input"
            value={endDate}
            onChange={e => setEndDate(e.target.value)}
            placeholder="End Date"
            />*/}

            <div className="col-12 col-lg-12 col-xl-4 d-flex justify-content-center justify-content-xl-start" style={{ marginTop: "10px" }}>
                <button onClick={onFilter} className="header-button" style={{ background: "#4d7cff", fontSize: "0.9rem", padding: "2px 10px", border: "none", height: "30px" }}>Filter</button>
                <button onClick={onClear} className="header-button" style={{ background: "#fc4a9e", fontSize: "0.9rem", padding: "2px 10px", border: "none", height: "30px" }}>Clear</button>
                <button onClick={onDeleteCount} className="header-button" style={{ background: "#fc5661", fontSize: "0.9rem", padding: "2px 10px", border: "none", height: "30px" }}>Delete</button>
            </div>
        </div>
    )
}