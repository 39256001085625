import React from 'react'
import footerbg from '../../public/assets/images/footer/footer-bg.webp'
import footerbefore from '../../public/assets/images/footer/footer-before.webp'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-solid-svg-icons'
import { faTwitter, faFontAwesome, faInstagram } from '@fortawesome/free-brands-svg-icons'

export default function Footer () {
    return(
        <footer className="footer-section bg_img" data-background={ footerbg } style={{"backgroundImage": `url(${footerbg})`, "paddingTop": "120px"}}>
            <div className="container">
                <div className="footer-before">
                    <img src={ footerbefore } alt="scanner screenshot"/>
                </div>
                <div className="d-flex flex-column justify-content-center align-items-center" style={{ zIndex: 2, position: 'relative' }}>
                    <a href="/" className="text-decoration-none text-white d-flex justify-content-center align-items-center">
                        <img className="mx-3" src="/logo/ico/logo-64.ico" alt="logo" />
                        <h4>Momoscreener</h4>
                    </a>
                    <div className="my-1 d-flex justify-content-center">
                        <div data-aos="fade-up" data-aos-duration="500" className="social-icons">
                            <span>
                                <a style={{ textDecoration: "none", color: "#ff4343" }} href="https://www.instagram.com/momoscreener" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon size="2x" icon={faInstagram} /></a>
                            </span>
                        </div>
                    </div>
                </div>
                <div className="my-5 footer-link d-flex justify-content-center flex-wrap">
                    <a href="/">Home</a>
                    <span></span>
                    <a href="/scanner">Scanner</a>
                    <span></span>
                    <a href="/charts">Charts</a>
                    <span></span>
                    <a href="/trades">Trades</a>
                    <span></span>
                    <a href="mailto:contact@momoscreener.com">Contact</a>
                </div>
                <div className="copyright d-flex justify-content-center">
                    <p>
                        Copyright © { new Date().getFullYear() }. All Rights Reserved By <a className="text-decoration-none" style={{ "color": "#ff4343" }} href="/">Momoscreener</a>
                    </p>
                </div>
            </div>
        </footer>
    )
}