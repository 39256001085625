import Chroma from 'chroma-js'

export const getColorFunction = (quotes, keyName, breakEven = 0) => {
    // console.log(quotes[keyName])
    let changeArray = quotes.map(quote => quote[keyName])
    // console.log(changeArray)
    let maxDistance = Math.max(Math.abs(Math.max(...changeArray)), Math.abs(Math.min(...changeArray)))
    if(!isNaN(maxDistance) && isFinite(maxDistance)){
        // console.log(`Max distance ${maxDistance}`)
        let maxColor = Chroma.scale(['C5E8B7', 'green']).domain([breakEven,maxDistance])
        let minColor = Chroma.scale(['F6BDC0', 'red']).domain([breakEven,maxDistance])
        maxColor.out('hex')
        minColor.out('hex')
        quotes.forEach(quote => {
            let colorValue = "#ffffff"
            if(quote[keyName] > breakEven){
                colorValue = maxColor(quote[keyName])
            } else if(quote[keyName] < breakEven){
                colorValue = minColor(Math.abs(quote[keyName]))
            }
            quote[`${keyName}-color`] = colorValue
        })
    }
    return quotes
}


export const getColorOnKey = (quotes, haltedColor = true) => {
    let floatColorScale = Chroma.scale(['#4cf72f', '#96df00', '#fe5023', '#fa2a43']).domain([5, 10, 15, 20, 40, 50, 100, 1000])
    floatColorScale.out('hex')
    
    let spreadColorScale = Chroma.scale(['#4cf72f', '#9fda00', '#dda900', '#fa2a43']).domain([0.3, 0.6, 0.8, 1.5])
    spreadColorScale.out('hex')
    
    quotes.forEach(quote => {
        if('marketCapFloatNumber' in quote) quote[`marketCapFloat-color`] = floatColorScale(Math.abs(quote['marketCapFloatNumber']))
        
        if('spread' in quote && haltedColor) quote[`spread-color`] = spreadColorScale(quote["spread"])
    })
    return quotes
}