import { useReducer, createContext, useMemo } from 'react'
import _ from 'lodash'
import useDefaultData from './useDefaultData'

export const GlobalContext = createContext()
let defaultData = ""

let initialState = {
    sound: false,
    loginData: null,
    theme: {
        name: 'dark',
        isLight: false
    }
}

function reducer(state = initialState, action) {
    switch (action.type) {
        case "voice":
            return {
                ...state,
                sound: action.value
            }
        case "theme":
            let selectedTheme = _.find(defaultData.themes, { name: action.value });
            return {
                ...state,
                theme: selectedTheme
            }
        case "login":
            return {
                ...state,
                loginData: action.value
            }
        default:
            return { ...state }
    }
}


export const GlobalStateContextProvider = props => {
    defaultData = useDefaultData()
    const [globalState, globalStateDispatch] = useReducer(reducer, initialState)

    return (
        <GlobalContext.Provider value={[globalState, globalStateDispatch]}>
            {props.children}
        </GlobalContext.Provider>
    )
}





