import { useContext } from "react";
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tooltip, OverlayTrigger } from 'react-bootstrap'
import { faStar } from '@fortawesome/free-solid-svg-icons'

import { GlobalContext } from '../controllers/useContextReducer'
import { getTimeFromLong } from '../utils/jshelper'


export default function VanilaScreenerTable({ columns, data, setOnClick, tableName }) {
  // global context
  const [globalState, globalStateDispatch] = useContext(GlobalContext)
  const tickerRegex = /^([^\(]+)/

  function setOnClickEvent(e) {
    let tickerName = e.target.parentNode.firstChild.textContent
    if (tickerName) {
      let regexResult = tickerRegex.exec(tickerName)
      if (regexResult && regexResult[0]) {
        setOnClick(regexResult[0])
      }
    }
  }

  function renderTableHead() {
    let tableHeadHtml = []
    let uniqKey = 0
    for (let eachHeader of columns) {
      tableHeadHtml.push(
        <th key={uniqKey}>
          {eachHeader.Header}
        </th>
      )
      uniqKey++
    }
    return tableHeadHtml;
  }

  function renderTableData() {
    let tableDataHtml = []
    let uniqKey = 0
    if (data) {
      for (let eachData of data) {
        let tableEachDataHtml = []
        for (let eachHeader of columns) {
          let style = {}
          let starHtml = ""
          let hodHtml = ""
          if (eachHeader.Header === "Volume") {
            globalState.theme.isLight === true ? style.backgroundColor = `rgba(${eachData.volColor}, 0.8)` : style.color = `rgba(${eachData.volColor}, 0.8)`
          // } else if (eachHeader.Header === "Float") {
            // globalState.theme.isLight === true ? style.backgroundColor = `rgba(${eachData.floatColor}, 0.8)` : style.color = `rgba(${eachData.floatColor}, 0.8)`
          } else if (eachHeader.Header === "Change" || eachHeader.Header === "Float" || eachHeader.Header === "Spr(%)" || eachHeader.Header === "5m" || eachHeader.Header === "10m") {
            globalState.theme.isLight === true ? style.backgroundColor = eachData[`${eachHeader.accessor}-color`] : style.color = eachData[`${eachHeader.accessor}-color`]
          }

          style.padding = "0.35rem"
          if (eachHeader.Header === "Symbol") {
            if (eachData['newsColor']) {
              starHtml = <OverlayTrigger
                delay={{ hide: 150, show: 10 }}
                overlay={(props) => (
                  <Tooltip {...props}>
                    {eachData.news['headline']}<br />({getTimeFromLong(new Date(eachData.news['newsTimeInLong']))})
                  </Tooltip>
                )}
                placement="bottom"
              ><div style={{ display: "inline" }}>
                  <FontAwesomeIcon icon={faStar} style={{ fontSize: "0.6rem", marginBottom: "3px", marginLeft: "3px", color: eachData['newsColor'] }} />
                </div>
              </OverlayTrigger>
            }
            if (eachData['hod']) {
              hodHtml = <OverlayTrigger
                delay={{ hide: 150, show: 10 }}
                overlay={(props) => (
                  <Tooltip {...props}>
                    High of the day
                </Tooltip>
                )}
                placement="top"
              ><div className="ms-1" style={{ display: "inline", fontSize: "9px" }}>
                  (HOD)
              </div>
              </OverlayTrigger>
            }
            style.backgroundColor = eachData[`${eachHeader.accessor}-color`]
            style.cursor = "pointer"
            tableEachDataHtml.push(<td
              onClick={e => setOnClickEvent(e)}
              key={uniqKey}
              style={style}>
              {_.get(eachData, eachHeader.accessor)}{starHtml}{hodHtml}
            </td>)
          } else {
            tableEachDataHtml.push(<td
              key={uniqKey}
              style={style}>
              {_.get(eachData, eachHeader.accessor)}
            </td>)
          }
          uniqKey++
        }
        tableDataHtml.push(
          <tr key={uniqKey}>
            {tableEachDataHtml}
          </tr>
        )
        uniqKey++
      }
    }
    return tableDataHtml
  }




  /* 
    Render the UI for your table
    - react-table doesn't have UI, it's headless. We just need to put the react-table props from the Hooks, and it will do its magic automatically
  */
  return (
    <div style={{
      overflow: "auto",
      minHeight: "400px",
      height: "400px",
    }}>
      <table className="table tableFixHead">
        <thead className="table-header">
          <tr>
            {renderTableHead()}
          </tr>
        </thead>
        <tbody>
          {renderTableData()}
        </tbody>
      </table>
    </div>
  );
}
