import React from 'react'
import { useContext, useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog } from '@fortawesome/free-solid-svg-icons'

import { GlobalContext } from '../controllers/useContextReducer'
import helper from '../controllers/helper'

const Header = () => {
    // sound
    const [sound, setSound] = useState(false)
    // global context
    const [globalState, globalStateDispatch] = useContext(GlobalContext)

    // set default values
    useEffect(() => {
        globalStateDispatch({ type: "login", value: helper.getUserInfo() })
        globalStateDispatch({ type: "voice", value: sound })
    }, [])


    async function onChangeSound() {
        setSound(!sound)
        globalStateDispatch({ type: "voice", value: !sound })
    }

    // clock
    const [time, setTime] = useState([getTime()])

    function getTime() {
        // let currentTime = new Date(new Date().toLocaleString("en-US", { timeZone: "America/New_York" }));
        let currentTime = new Date();
        return `${getTimeTwoDigitFormat(currentTime.getHours())}:${getTimeTwoDigitFormat(currentTime.getMinutes())}:${getTimeTwoDigitFormat(currentTime.getSeconds())}`
    }

    function getTimeTwoDigitFormat(number) {
        return (number < 10 ? '0' : '') + number
    }

    useEffect(() => {
        let clockInterval = setInterval(() => {
            setTime(getTime())
        }, 1000)

        return (() => {
            clearInterval(clockInterval)
        })
    }, [])

    const clock = {
        transform: "translateX(-50%) translateY(-50%)",
        fontSize: "30px",
        fontFamily: "Orbitron",
        letterSpacing: "2px",
    }

    function handleLogout() {
        /* global localStorage */
        localStorage.removeItem('loginData')
        localStorage.removeItem('userPnL')
        localStorage.removeItem('filtertrades')
        globalStateDispatch({ type: "login", value: null })
    }

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-sm-12 col-md-6" style={{ padding: "10px 40px 5px 40px" }}>
                    <div className="d-flex justify-content-between flex-wrap">
                        <div className="d-flex justify-content-start flex-wrap">
                            <div style={{ marginRight: "40px" }}>
                                <Link to="/" style={{ textDecoration: "none", color: "#FFFFFF" }}>
                                    <img className="float-start me-3" src="/logo/ico/logo-64.ico" height="64" width="64" alt="logo" />
                                    <h2 className="float-end pt-2">
                                        Momo Screener
                                    </h2>
                                </Link>
                            </div>

                            <div style={{ marginTop: "17px" }}>
                                <Link className="header-button" to="/">Home</Link>
                                <Link className="header-button" to="/scanner">Scanner</Link>
                                <Link className="header-button" to="/daytrade">DayTrade</Link>
                                <Link className="header-button" to="/charts">Charts</Link>
                                <Link className="header-button" to="/trades">Trades</Link>
                            </div>
                        </div>
                        <div style={{ marginTop: "13px" }}>
                            {(window.location.pathname == "/scanner" || window.location.pathname == "/daytrade") &&
                                <div className="d-flex mt-1">
                                    <div className="form-check form-switch p-0">
                                        <label className="form-check-label float-start" style={{ marginRight: "45px" }} htmlFor="flexSwitchCheckDefault">Turn on sound</label>
                                        <input className="form-check-input float-end" type="checkbox" id="flexSwitchCheckDefault" checked={sound} onChange={onChangeSound} />
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>

                <div className="col-sm-12 col-md-6" style={{ padding: "10px 40px 5px 40px" }}>
                    {window.location.pathname != "/trades" &&
                        <div className="float-start">
                            <span style={clock}> {time} </span>
                        </div>
                    }
                    {
                        window.location.pathname == "/trades" && globalState.loginData &&
                        <div className="float-start">
                            <button onClick={handleLogout} style={{ padding: "5px 12px 9px 46px", backgroundPosition: "12px 8px", marginTop: "8px" }} className='login-with-google-btn'>Logout</button>
                        </div>
                    }
                    <div className="float-end">
                        <form action="https://www.paypal.com/donate" method="post" target="_top">
                            <input type="hidden" name="business" value="GZMC655EBVMLE" />
                            <input type="hidden" name="no_recurring" value="0" />
                            <input type="hidden" name="item_name" value="Donations are absolutely not a must but greatly appreciated.
                            Thank you for supporting :)" />
                            <input type="hidden" name="currency_code" value="USD" />
                            <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
                            <img alt="" border="0" src="https://www.paypal.com/en_US/i/scr/pixel.gif" width="1" height="1" />
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header
