import React, { useEffect } from "react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGripHorizontal } from '@fortawesome/free-solid-svg-icons'


export default function GlobalFuture({ title }) {

    useEffect(() => {
        const script = document.createElement('script');

        script.src = "https://s3.tradingview.com/external-embedding/embed-widget-market-quotes.js";
        script.async = true;
        script.innerHTML = JSON.stringify({
            "width": "100%",
            "height": "460",
            "symbolsGroups": [
                {
                    "name": "Indices",
                    "originalName": "Indices",
                    "symbols": [
                        {
                            "name": "FOREXCOM:SPXUSD",
                            "displayName": "S&P 500"
                        },
                        {
                            "name": "FOREXCOM:NSXUSD",
                            "displayName": "US 100"
                        },
                        {
                            "name": "FOREXCOM:DJI",
                            "displayName": "Dow 30"
                        },
                        {
                            "name": "INDEX:SENSEX",
                            "displayName": "Sensex IND"
                        },
                        {
                            "name": "INDEX:NKY",
                            "displayName": "Nikkei 225"
                        },
                        {
                            "name": "INDEX:DEU40",
                            "displayName": "DAX Index"
                        },
                        {
                            "name": "FOREXCOM:UKXGBP",
                            "displayName": "UK 100"
                        }
                    ]
                },
                {
                    "name": "Futures",
                    "originalName": "Futures",
                    "symbols": [
                        {
                            "name": "CME_MINI:ES1!",
                            "displayName": "S&P 500"
                        },
                        {
                            "name": "CME_MINI:NQ1!",
                            "displayName": "Nasdaq"
                        }
                    ]
                }
            ],
            "showSymbolLogo": true,
            "colorTheme": "dark",
            "isTransparent": false,
            "locale": "en"
        })

        document.getElementsByClassName("tradingview-widget-container-market")[0].appendChild(script);
    }, []);
    return (
        <div style={{
            overflow: "auto",
            minHeight: "100px",
            height: "550px"
        }}>

            <div className="card"
                style={{
                    overflow: "auto",
                    resize: "both"
                }}>
                <div className="card-header">
                    <div className="container d-inline-flex justify-content-between">
                        <div className="align-self-start">
                            {title}
                        </div>
                        <FontAwesomeIcon className="dragHandle" variant="secondary" icon={faGripHorizontal} style={{ fontSize: "1.1rem", margin: "5px 10px 0px" }} />
                        <div></div>
                    </div>
                </div>
                <div className="card-body pt-0">
                    <div className="tradingview-widget-container-market">
                    </div>
                </div>
            </div>
        </div>
    );
}
