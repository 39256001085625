import Charts from './Charts'

import { Helmet } from 'react-helmet-async';

function TradesRoute() {
    return (
        <>
            <Helmet>
                <meta name="description" content="Free trading charts to analyze stocks on multiple timeframe with trading indicators" />
                <meta name="keywords" content="free stock charts, stock charts, stock chart indicators, VWAP EMA indicators, stock chart analysis, intraday stock charts, online stock charts" />
                
                <meta name="twitter:title" value="MomoScreener Trading Journal - Analyze and Stats" />
                <meta name="twitter:description" content="Free trading charts to analyze stocks on multiple timeframe with trading indicators" />
                
                <meta name="og:title" property="og:title" content="MomoScreener Trading Journal - Analyze and Stats" />
                <meta name="og:description" property="og:description" content="Free trading charts to analyze stocks on multiple timeframe with trading indicators" />
                
                <title>MomoScreener Charting Tool</title>
            </Helmet>
            <Charts />
        </>
    )
}

export default TradesRoute
