import React, { useState, useEffect, useRef } from 'react'
import AsyncSelect from 'react-select/async';
import Draggable from 'react-draggable';
// import './App.css';
import "../../public/root.css";
import "../../public/style.css";

import helper from '../../controllers/helper'

import { GlobalStateContextProvider } from '../../controllers/useContextReducer'
import useTradingViewChart from '../../controllers/useTradingViewChart'

import TradingViewChart from '../TradingViewChart'

function Chart({ providedTicker, uniqId }) {

    function updateIndex(e) {
        let dragHandles = document.getElementsByClassName("dragHandle");
        for (let eachHandle of dragHandles) {
            if (eachHandle) {
                let el = eachHandle.closest(".react-draggable");
                if (el) {
                    el.style.zIndex = 1
                }
            }
        }
        let el = e.target.closest(".react-draggable");
        if (el) {
            el.style.zIndex = 90
        }
    }
    const nodeRef = useRef(null);

    const [localTicker, setlocalTicker] = useState(providedTicker)
    const [ticker, setticker] = useState(providedTicker)

    function updateticker(e) {
        if (e.key === 'Enter') {
            let newTicker = e.target.value
            if (newTicker) {
                setticker(newTicker)
            }
        }
    }
    useTradingViewChart(ticker, 0, `tradeViewChartAnalysis${uniqId}`, `livechart${uniqId}`, 1)


    let controller = null;
    const promiseOptions = (inputValue) =>
        new Promise(async (resolve, reject) => {
            try {
                if (inputValue) {
                    if (controller) controller.abort();
                    controller = new AbortController();
                    let stockSuggestions = await fetch(`${helper.apiUrl}/stocklist?q=${inputValue}`, { signal: controller.signal });
                    controller = null;
                    stockSuggestions = await stockSuggestions.json();
                    let autocompleteList = []
                    if (stockSuggestions && stockSuggestions.length > 0) {
                        for (let stock of stockSuggestions) {
                            autocompleteList.push({
                                value: stock.symbol,
                                label: `${stock.symbol} - ${stock.description}`
                            })
                        }
                        return resolve(autocompleteList)
                    } else {
                        return resolve()
                    }
                } else {
                    return reject()
                }
            } catch (error) {

            }
        })

    const autoCompleteStyle = {
        container: (styles) => ({
            ...styles,
            width: "100%",
            marginBottom: "20px"
        }),
        control: (styles) => ({
            ...styles,
            backgroundColor: '#2b2e4c',
            height: "45px",
            paddingLeft: "10px",
            textTransform: "uppercase",
            border: "none",
        }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                backgroundColor: isFocused ? "#d41459" : "#2b2e4c",
                cursor: "pointer",
                color: '#c8c6c6',
                textTransform: "uppercase",
                ':hover': {
                    ...styles[':hover'],
                    background: "#d41459"
                }
            }
        },
        menu: base => ({
            ...base,
            background: "#2b2e4c",
            color: '#c8c6c6',
            maxWidth: "500px",
            minWidth: "350px",
            fontSize: "12px",
            zIndex: 5
        }),
        menuList: base => ({
            ...base,
            paddingTop: 0,
            paddingBottom: 0,
        }),
        singleValue: provided => ({
            ...provided,
            color: '#c8c6c6',
        }),
        input: provided => ({
            ...provided,
            color: '#c8c6c6',
            minWidth: "100%"
        }),
        placeholder: (styles) => ({
            ...styles,
            width: "100%"
        })
    }

    return (
        <GlobalStateContextProvider>


            <div className="container-fluid m-0 p-0">
                <div className="row">

                    <div className="col-md-3 col-sm-12 d-flex justify-content-center justify-content-md-start">
                        <AsyncSelect
                            cacheOptions
                            defaultOptions
                            loadOptions={promiseOptions}
                            styles={autoCompleteStyle}
                            value={{ value: localTicker, label: localTicker }}
                            // placeholder={localTicker}
                            onChange={e => {
                                setlocalTicker(e.value)
                                setticker(e.value)
                            }}
                            noOptionsMessage={() => null}
                            components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                            inputId="uppercase"
                            inputClassName="w-md-100"
                            classNamePrefix="react-select"
                        />



                        {/*<input type="text"
                                    style={{ "width": "150px", "height": "35px", "textTransform": "uppercase", "marginBottom": "20px" }}
                                    value={localTicker}
                                    onChange={e => setlocalTicker(e.target.value)}
                                    onKeyDown={updateticker}
                                    className="form-input"
                                    placeholder="Symbol" />*/}
                    </div>
                </div>
                <div className="row">

                    <Draggable
                        handle=".dragHandle"
                        scale={1}
                        defaultPosition={{ x: 0, y: 0 }}
                        position={null}
                        onStart={updateIndex}
                        nodeRef={nodeRef}>
                        <div className="col-sm-12"
                            onClick={updateIndex}
                            ref={nodeRef}>
                            <TradingViewChart
                                idName={`tradeViewChartAnalysis${uniqId}`}
                                title="Chart"
                                ticker={ticker}
                                height="375"
                            />
                        </div>
                    </Draggable>

                </div>
            </div>
        </GlobalStateContextProvider>
    );
}

export default Chart;
