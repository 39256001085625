import Trades from './Trades'

import { Helmet } from 'react-helmet-async';

function TradesRoute() {
    return (
        <>
            <Helmet>
                <meta name="description" content="Free trading journal to analyze and improve trading performance based on the statistics" />
                <meta name="keywords" content="free trading journal, stock trading journal, equity trading journal, trading log, trade log, day trading journal, intraday trading journal, intraday trade journal, trade analyzer, trading analyzer, analyze trades, analyze trading, trading performance, trade journal" />
                
                <meta name="twitter:title" value="MomoScreener Trading Journal - Analyze and Stats" />
                <meta name="twitter:description" content="Free trading journal to analyze and improve trading performance based on the statistics" />
                
                <meta name="og:title" property="og:title" content="MomoScreener Trading Journal - Analyze and Stats" />
                <meta name="og:description" property="og:description" content="Free trading journal to analyze and improve trading performance based on the statistics" />
                
                <title>MomoScreener Trading Journal - Analyze and Stats</title>
            </Helmet>
            <Trades />
        </>
    )
}

export default TradesRoute
