import './App.css';
import "./public/root.css";
import "./public/style.css";

import { Helmet } from 'react-helmet-async';
// import ReactGA from 'react-ga'

import { GlobalStateContextProvider } from './controllers/useContextReducer'

import Header from './components/Header';
import Screeners from './components/Screeners'

// ReactGA.initialize('UA-216186805-1', {
//   siteSpeedSampleRate: 100,
//   alwaysSendReferrer: true
// })
// ReactGA.pageview(window.location.pathname + window.location.search);

function App() {
  return (
    <GlobalStateContextProvider>
      <Helmet prioritizeSeoTags>
        <title>MomoScreener - Find momentum stocks for day traders</title>
        
        <meta name="description" content="MomoScreener finds the momentum stock of the day with the latest news in the real-time. High of the day, Stock screener for day traders, Top gappers, Free stock screeners, Free stock scanner, Live news, Find hot stocks" />
        <meta name="keywords" content="High of the day, Stock screener for day traders, Find hot stocks, Penny stocks, Real-time stock scanners, Stocks with news, Top gappers, Free stock screeners, Free stock scanner, Live news, Filter stocks" />
        
        <meta name="twitter:title" value="Momo Screener - Find momentum stocks for the day trading" />
        <meta name="twitter:description" content="MomoScreener finds the momentum stock of the day with the latest news in the real-time. High of the day, Stock screener for day traders, Top gappers, Free stock screeners, Free stock scanner, Live news, Find hot stocks" />
    
        <meta name="og:title" property="og:title" content="Momo Screener - Find momentum stocks for the day trading" />
        <meta name="og:description" property="og:description" content="MomoScreener finds the momentum stock of the day with the latest news in the real-time. High of the day, Stock screener for day traders, Top gappers, Free stock screeners, Free stock scanner, Live news, Find hot stocks" />
      </Helmet>
      <div className="StockScreener">
        <Header />
        <Screeners />
      </div>
    </GlobalStateContextProvider>
  );
}

export default App;
