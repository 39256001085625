import { useMemo } from 'react'
import _ from 'lodash'

export default function useScreenerColumn(tableName) {
    return useMemo(() => {
        let allColumns = [
            {
                Header: "Symbol",
                accessor: "symbol"
            },
            {
                Header: "Price",
                accessor: "price",
                sortType: 'basic'
            },
            {
                Header: "Change(%)",
                accessor: "change",
                sortType: 'basic'
            },
            {
                Header: "5m",
                accessor: "change5m",
                sortType: 'basic'
            },
            {
                Header: "Float",
                accessor: "marketCapFloat",
                sortType: 'basic'
            },
            {
                Header: "Volume",
                accessor: "totalVolume",
                sortType: 'basic'
            },
            // {
            //     Header: "Bid",
            //     accessor: "quote.bidPrice",
            //     sortType: 'basic'
            // },
            // {
            //     Header: "Ask",
            //     accessor: "quote.askPrice",
            //     sortType: 'basic'
            // },
            {
                Header: "Spr(%)",
                accessor: "spread",
                sortType: 'basic'
            },
            {
                Header: 'Time',
                accessor: "quote.date"
            },
            // {
            //     Header: 'Now',
            //     accessor: "currentDate"
            // }
        ]

        let gapperColumns = JSON.parse(JSON.stringify(allColumns))
        gapperColumns = _.remove(gapperColumns, function (columnName) {
            return columnName['Header'] !== "5m";
        })
        let momo5m3cColumns = JSON.parse(JSON.stringify(allColumns))
        let haltColumns = JSON.parse(JSON.stringify(allColumns))
        haltColumns = _.remove(haltColumns, function (columnName) {
            return columnName['Header'] !== "Spr(%)";
        })

        let columns = {
            "gapperHigh": gapperColumns,
            "halt": haltColumns,
            "momo5m3c": momo5m3cColumns
        }

        return (columns[tableName])
    }, [])
}