import { useRef, useState, PureComponent } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGripHorizontal } from '@fortawesome/free-solid-svg-icons'
import {
  BarChart,
  Bar,
  Brush,
  Cell,
  ReferenceLine,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
}
from "recharts";

export default function CBarChart({ data }) {
  if (!data || data.length === 0) return (<></>)
  const dollarFormatter = item => item && `$${item}`

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="recharts-custom-tooltip d-flex flex-column">
                <div className="recharts-label">{`Date: ${label}`}</div>
                <div className="recharts-label">{`Profit/Loss : $${(payload[0].value).toFixed(2)}`}</div>
                <div className="recharts-label">{`Avg P/L per trade : $${payload[0] && payload[0].payload && (payload[0].payload.pnl / payload[0].payload.transactions).toFixed(2)}`}</div>
                <div className="recharts-label">{`Total trades : ${payload[0] && payload[0].payload && payload[0].payload.transactions}`}</div>
                <div className="recharts-label">{`Total Volume : ${payload[0] && payload[0].payload && payload[0].payload.volume}`}</div>
                <div className="recharts-label">{`Avg Volume per trade : ${payload[0] && payload[0].payload && (payload[0].payload.volume / payload[0].payload.transactions).toFixed(2)}`}</div>
              </div>
      );
    }
    return null;
  }

  class CustomizedAxisTick extends PureComponent {
    render() {
      const { x, y, stroke, payload } = this.props;

      return (
        <g transform={`translate(${x},${y})`}>
              <text x={0} y={0} dy={16} textAnchor="end" fill="#666" fontSize={12} transform="rotate(-35)">
                {payload.value}
              </text>
            </g>
      );
    }
  }

  const dateFormatter = (item => {
    if (item && item != "auto") {
      try {
        return new Date(item).toISOString().slice(0, 10)
      }
      catch (e) {
        return
      }
    }
    else {
      return
    }
  })

  return (
    <div className="card"
    style={{
        overflow: "auto",
        resize: "both",
        marginTop: "30px"
    }}>
      <div className="card-header">
          <div className="container d-inline-flex justify-content-between">
              <div className="align-self-start">
                  Profit/Loss by day
              </div>
              <FontAwesomeIcon className="dragHandle" variant="secondary" icon={faGripHorizontal} style={{ fontSize: "1.1rem", margin: "5px 10px 0px" }} />
              <div></div>
          </div>
      </div>
      <div className="card-body"
          style={{
              overflow: "auto",
              height: "435px"
          }}>
          <ResponsiveContainer width="100%" height="100%">
              <BarChart
                syncId="plInfo"
                data={data}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5
                }}
              >
                <CartesianGrid strokeOpacity={0.2} strokeDasharray="3 3"/>
                <XAxis dataKey="date" tickFormatter={dateFormatter} height={60} minTickGap={30} stroke="#c8c6c6" tick={<CustomizedAxisTick />} />
                <YAxis tickFormatter={dollarFormatter} stroke="#c8c6c6" tick={{fontSize: 12}} width={40}/>
                <Tooltip content={<CustomTooltip />} cursor={false} />
                <Legend verticalAlign="top" wrapperStyle={{ lineHeight: "40px" }} />
                <ReferenceLine y={0} stroke="#000" />
                <Bar 
                animationDuration={2000}
                dataKey="pnl"
                name="P/L per day"
                fill="#8884d8"
                >
                  {data.map((entry, index) => (
                      <Cell key={index} fill={data[index].pnl < 0 ? "#fc5661" : "#0acf97"} />
                  ))}
                </Bar>
              </BarChart>
          </ResponsiveContainer>
      </div>
    </div>
  )
}
