import React, { useEffect, useState, useRef } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog, faQuestionCircle, faGripHorizontal } from '@fortawesome/free-solid-svg-icons'
import Draggable from 'react-draggable';

import { numberConversion, getTimeTwoDigitFormat, getTimeFromLong } from '../utils/jshelper'


function NewsBox({ title, newsInfo, setOnClick }) {
    const nodeRef = useRef(null);
    
    const tickerRegex = /^([^\(]+)/
    function setOnClickEvent(e) {
        let tickerName = e.target.textContent
        if (tickerName) {
            let regexResult = tickerRegex.exec(tickerName)
            if (regexResult && regexResult[0]) {
                setOnClick(regexResult[0])
            }
        }
    }

    // get news table
    function getNews() {
        let newsTable = []
        if (newsInfo) {
            for (var i = 0; i < newsInfo.length; i++) {
                if (i + 1 < newsInfo.length && newsInfo[i].id == newsInfo[i + 1].id) continue
                newsTable.push(<tr key={i}>
                    <td className="code-font">{newsInfo[i].headline} ({newsInfo[i].source}) {newsInfo[i].isHot ? "HOT" : ""}</td>
                    <td
                        className="text-nowrap"
                        style={{ cursor: "pointer" }}
                        onClick={e => setOnClickEvent(e)}>
                        {newsInfo[i].symbol}
                    </td>
                    <td style={{ color: "#71b6f9" }} className="text-nowrap">{getTimeFromLong(new Date(newsInfo[i].newsTimeInLong))}</td>
                </tr>
                );
            }
        }
        return newsTable
    }

    return (
        <div className="card"
            ref={nodeRef}
            style={{
                overflow: "auto",
                resize: "both"
            }}>
            <div className="card-header">
                <div className="container d-inline-flex justify-content-between">
                    <div className="align-self-start">
                        {title}
                    </div>
                    <FontAwesomeIcon className="dragHandle" variant="secondary" icon={faGripHorizontal} style={{ fontSize: "1.1rem", margin: "5px 10px 0px" }} />
                    <div></div>
                </div>
            </div>
            <div className="card-body">
                <div className="row mb-1">
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div style={{
                            overflow: "auto",
                            minHeight: "100px",
                            height: "400px"
                        }}>
                            <table className="table tableFixHead table-condensed">
                                <thead className="table-header">
                                    <tr>
                                        <th>Headline</th>
                                        <th>Symbol</th>
                                        <th>News Time</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {getNews()}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card-footer text-muted">
                Last updated: {getTimeFromLong(new Date())}
            </div>
        </div>
    )
}

export default NewsBox