import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGripHorizontal } from '@fortawesome/free-solid-svg-icons'

export default function OverView({ data }) {
    if (!data || data.length === 0 || (data && data.totalDays == 0)) return (<></>)
    // console.log(data)
    return (
        <div className="card"
            style={{
                overflow: "auto",
                resize: "both",
                marginTop: "30px"
            }}>
            <div className="card-header">
                <div className="container d-inline-flex justify-content-between">
                    <div className="align-self-start">
                        Profit/Loss Stats
                    </div>
                    <FontAwesomeIcon className="dragHandle" variant="secondary" icon={faGripHorizontal} style={{ fontSize: "1.1rem", margin: "5px 10px 0px" }} />
                    <div></div>
                </div>
            </div>
            <div className="card-body"
                style={{
                    overflow: "auto",
                    height: "435px"
                }}>
                <div className="row" style={{ "fontSize": "14px" }}>
                    <div className="col-md-6">
                        <table className="table table-sm">
                            <tbody>

                                <tr>
                                    <td>Total profit/loss:</td>
                                    <td>${data.totalPnL.toFixed(2)}</td>
                                </tr>

                                <tr>
                                    <td>Average daily profit/loss:</td>
                                    <td>${(data.totalPnL / data.totalDays).toFixed(2)}</td>
                                </tr>

                                <tr>
                                    <td>Total trading days:</td>
                                    <td>{data.totalDays} days</td>
                                </tr>

                                <tr>
                                    <td>Average daily volume:</td>
                                    <td>{(data.totalVolume / data.totalDays).toFixed(0)}</td>
                                </tr>

                                <tr>
                                    <td>Average winning trade:</td>
                                    <td>${(data.totalWinAmount / data.totalWinTrade).toFixed(2)}</td>
                                </tr>

                                <tr>
                                    <td>Average losing trade:</td>
                                    <td>${(data.totalLossAmount / data.totalLossTrade).toFixed(2)}</td>
                                </tr>

                                <tr>
                                    <td>Number of winning trades:</td>
                                    <td>{data.totalWinTrade} ({((data.totalWinTrade / (data.totalWinTrade + data.totalLossTrade + data.totalScratchTrade)) * 100).toFixed(1)}%)</td>
                                </tr>

                                <tr>
                                    <td>Number of losing trades:</td>
                                    <td>{data.totalLossTrade} ({((data.totalLossTrade / (data.totalWinTrade + data.totalLossTrade + data.totalScratchTrade)) * 100).toFixed(1)}%)</td>
                                </tr>

                                <tr>
                                    <td>Number of scratch trades:</td>
                                    <td>{data.totalScratchTrade} ({((data.totalScratchTrade / (data.totalWinTrade + data.totalLossTrade + data.totalScratchTrade)) * 100).toFixed(1)}%)</td>
                                </tr>

                                <tr>
                                    <td>Total Fees:</td>
                                    <td>{data.totalFees && data.totalFees.toFixed(2)}</td>
                                </tr>

                                <tr>
                                    <td>Total profit/loss after fees:</td>
                                    <td>${(data.totalPnL - (data.totalFees && data.totalFees)).toFixed(2)}</td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                    <div className="col-md-6">
                        <table className="table table-sm">
                            <tbody>

                                <tr>
                                    <td>Largest gain:</td>
                                    <td>${data.largestGain.toFixed(1)}</td>
                                </tr>

                                <tr>
                                    <td>Largest loss:</td>
                                    <td>${data.largestLoss.toFixed(1)}</td>
                                </tr>

                                <tr>
                                    <td>Total trades:</td>
                                    <td>{data.totalTrades.toFixed(0)}</td>
                                </tr>

                                <tr>
                                    <td>Avg per-trade gain/loss</td>
                                    <td>${data.avgPerTradePL.toFixed(3)}</td>
                                </tr>

                                <tr>
                                    <td>Avg per-share gain/loss</td>
                                    <td>${data.avgPerSharePL.toFixed(3)}</td>
                                </tr>

                                <tr>
                                    <td>Avg hold time:</td>
                                    <td>{data.avgHoldTime.toFixed(2)} secs</td>
                                </tr>

                                <tr>
                                    <td>Avg hold time (winning trades):</td>
                                    <td>{data.avgWinHoldTime.toFixed(2)} secs</td>
                                </tr>

                                <tr>
                                    <td>Avg hold time (losing trades):</td>
                                    <td>{data.avgLossHoldTime.toFixed(2)} secs</td>
                                </tr>

                                <tr>
                                    <td>Avg win trades volume:</td>
                                    <td>{((data.totalWinTradesVolume / data.totalWinTrade) / 2).toFixed(2)}</td>
                                </tr>

                                <tr>
                                    <td>Avg losing trades volume:</td>
                                    <td>{((data.totalLossTradesVolume / data.totalLossTrade) / 2).toFixed(2)}</td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}