import { useRef, useState, PureComponent } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGripHorizontal } from '@fortawesome/free-solid-svg-icons'
import {
    BarChart,
    Bar,
    Cell,
    ReferenceLine,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer
}
    from "recharts";

export default function CTopProfitLoss({ data }) {
    if (!data || (data.topRunner.length === 0 && data.bottomRunner.length === 0)) return (<></>)
    const dollarFormatter = item => item && `$${item}`

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className="recharts-custom-tooltip d-flex flex-column">
                    <div className="recharts-label">{`Ticker: ${label}`}</div>
                    <div className="recharts-label">{`Profit/Loss : $${(payload[0].value).toFixed(2)}`}</div>
                    <div className="recharts-label">{`Trades : ${(payload[0]?.payload?.trades?.length ? payload[0]?.payload?.trades?.length : payload[0]?.payload?.total_trades)}`}</div>
                    <div className="recharts-label">{`P/L per Trade : ${(payload[0].value / (payload[0]?.payload?.trades?.length ? payload[0]?.payload?.trades?.length : payload[0]?.payload?.total_trades)).toFixed(2)}`}</div>
                </div>
            );
        }
        return null;
    }

    return (
        <div className="card"
            style={{
                overflow: "auto",
                resize: "both",
                marginTop: "30px"
            }}>
            <div className="card-header">
                <div className="container d-inline-flex justify-content-between">
                    <div className="align-self-start">
                        Gainers and Losers
                    </div>
                    <FontAwesomeIcon className="dragHandle" variant="secondary" icon={faGripHorizontal} style={{ fontSize: "1.1rem", margin: "5px 10px 0px" }} />
                    <div></div>
                </div>
            </div>
            <div className="card-body"
                style={{
                    overflow: "auto",
                    height: "435px"
                }}>
                <div className="row" style={{ height: "100%", width: "100%" }}>
                    <div className="col-md-6 d-flex flex-column justify-content-center">
                        <ResponsiveContainer width="100%" height="100%">
                            <BarChart
                                data={data.topRunner}
                                layout="vertical"
                                barGap={0}
                                barCategoryGap={0}
                                margin={{
                                    top: 5,
                                    right: 30,
                                    left: 20,
                                    bottom: 5
                                }}
                            >
                                <CartesianGrid strokeOpacity={0.2} strokeDasharray="3 3" />
                                <XAxis dataKey="trades_pnl" stroke="#c8c6c6" type="number" />
                                <YAxis dataKey="ticker" stroke="#c8c6c6" tick={{ fontSize: 12 }} width={30} type="category" />
                                <Tooltip content={<CustomTooltip />} cursor={false} />
                                <Legend verticalAlign="top" wrapperStyle={{ lineHeight: "40px" }} />
                                <ReferenceLine y={0} stroke="#000" />
                                <Bar
                                    animationDuration={2000}
                                    dataKey="trades_pnl"
                                    barSize={20}
                                    name="Top winnner"
                                    fill="#8884d8"
                                >
                                    {data.topRunner.map((entry, index) => (
                                        <Cell key={index} fill="#0acf97" />
                                    ))}
                                </Bar>
                            </BarChart>
                        </ResponsiveContainer>
                    </div>

                    <div className="col-md-6 d-flex flex-column justify-content-center">
                        <ResponsiveContainer width="100%" height="100%">
                            <BarChart
                                data={data.bottomRunner}
                                layout="vertical"
                                barGap={0}
                                barCategoryGap={0}
                                margin={{
                                    top: 5,
                                    right: 30,
                                    left: 20,
                                    bottom: 5
                                }}
                            >
                                <CartesianGrid strokeOpacity={0.2} strokeDasharray="3 3" />
                                <XAxis dataKey="trades_pnl" stroke="#c8c6c6" type="number" />
                                <YAxis dataKey="ticker" stroke="#c8c6c6" tick={{ fontSize: 12 }} width={30} type="category" />
                                <Tooltip content={<CustomTooltip />} cursor={false} />
                                <Legend verticalAlign="top" wrapperStyle={{ lineHeight: "40px" }} />
                                <ReferenceLine y={0} stroke="#000" />
                                <Bar
                                    animationDuration={2000}
                                    dataKey="trades_pnl"
                                    barSize={20}
                                    name="Top loser"
                                    fill="#8884d8"
                                >
                                    {data.bottomRunner.map((entry, index) => (
                                        <Cell key={index} fill="#fc5661" />
                                    ))}
                                </Bar>
                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                </div>
            </div>
        </div>
    )
}