import React, { useCallback, useEffect, useState, useRef, useContext } from 'react'
import Dropzone, { useDropzone } from 'react-dropzone'
import Draggable from 'react-draggable';
import { Modal, Button, ListGroup } from 'react-bootstrap'
import $ from 'jquery'

import helper from '../../controllers/helper'
import useCalculateTrades from '../../controllers/useCalculateTrades'

import "../../public/root.css"
import "../../public/style.css"

import { GlobalStateContextProvider, GlobalContext } from '../../controllers/useContextReducer'
import Header from '../Header';
import TradeFilter from './TradeFilter'
import CLineChart from './CLineChart'
import CBarChart from './CBarChart'
import CBarChartByDay from './CBarChartByDay'
import CWinLossStats from './CWinLossStats'
import CTopProfitLoss from './CTopProfitLoss'
import CTimeStats from './CTimeStats'
import OverView from './OverView'

const BROKERS = [
    { label: "ThinkOrSwim", value: "tos" },
    // { label: "WeBull", value: "webull" }
]

function Trades() {
    const [globalState, globalStateDispatch] = useContext(GlobalContext)
    // const [userTradesAnalysis, setUserTradesAnalysis] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const [isCalculating, setIsCalculating] = useState(false)
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone();
    const [DnDMessage, setDnDMessage] = useState(`Upload statement(s)`)

    const [brokerName, setBrokerName] = useState(BROKERS[0].value)
    const [timeZone, setTimeZone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone)
    const [ticker, setTicker] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const [deleteTradesCount, setDeleteTradesCount] = useState(0)

    const [userPnL, setUserPnL] = useState({
        closedTrades: [],
        error: 0,
        openTrades: [],
        totalFees: 0,
        totalPnL: 0
    })

    const userTradesAnalysis = useCalculateTrades(userPnL)
    // console.log(userTradesAnalysis)

    // Once the file is selected, auto-process through draggable module
    const onDrop = useCallback((acceptedFiles) => {
        acceptedFiles.forEach((file) => {
            const reader = new FileReader()

            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = () => {
                const binaryStr = reader.result
                // console.log(binaryStr)
            }
            reader.readAsArrayBuffer(file)
        })
    }, [])

    // once the file is selected, process for uploading
    useEffect(() => {
        // uploadStatments()

    }, [acceptedFiles])

    async function uploadStatments() {
        if (acceptedFiles && acceptedFiles.length > 0 && acceptedFiles.length <= 10) {
            if (!timeZone || !brokerName) {
                alert('Please select timezone and broker name')
                return
            }
            if (!isLoading) {
                setIsLoading(true)
                let formData = new FormData()
                acceptedFiles.forEach((eachFile, i) => {
                    formData.append(`statement-${i}`, eachFile)
                })
                formData.append(`tz`, timeZone)
                formData.append(`broker`, brokerName)
                // console.log(`selected files ${acceptedFiles.length}`, acceptedFiles)

                try {
                    let tokenId = getTokenId()
                    if (tokenId) {
                        formData.append(`token`, tokenId)
                        let userIdDetails = await fetch(`${helper.apiUrl}/account/trades/upload`, {
                            method: 'POST',
                            body: formData,
                        })

                        if (userIdDetails) {
                            userIdDetails = await userIdDetails.json();
                            if (!evaluateResponse(userIdDetails)) return
                            if (userIdDetails && userIdDetails.user) {
                                await getFilteredValues()
                            } else if (userIdDetails && userIdDetails.error == 1 && userIdDetails.message) {
                                console.log(`Failed to upload a file, ${userIdDetails.message}`)
                            }
                            acceptedFiles.splice(0)
                            updateDnDMessage()
                        } else {
                            console.log(`Error: unexpected reply from statement upload API`)
                        }
                    }
                } catch (error) {
                    console.log(`error while uploading a file `, error)
                }
                setIsLoading(false)
            }
        } else {
            alert('Please select file, timezone and broker name')
        }
    }

    function getCalculatedValues(requestObj) {
        return new Promise(async (resolve, reject) => {
            let tokenId = getTokenId()
            /* global fetch */
            if (tokenId) {
                let userPnL = await fetch(`${helper.apiUrl}/account/trades/calculate?` + new URLSearchParams(requestObj).toString() + `&token=${tokenId}`)
                if (userPnL) {
                    userPnL = await userPnL.json();
                    if (!evaluateResponse(userPnL)) return
                    // localStorage.setItem("userPnL", JSON.stringify(userPnL))

                    // check if the back-end is still calculating then call the same function again in 1 sec
                    if (userPnL?.data?.isCalculate == 1) {
                        setIsCalculating(true)
                        setTimeout(async () => {
                            // console.log(`Calculating`)
                            await getCalculatedValues(requestObj)
                        }, 1000)
                    } else {
                        setIsCalculating(false)
                        setIsLoading(false)
                        setUserPnL(userPnL)
                        return resolve()
                    }
                } else {
                    return resolve()
                }
            } else {
                return resolve()
            }
        })
    }

    function evaluateResponse(response) {
        if (response.loginError) {
            logoutUser()
            setIsLoading(false)
            return false
        } else {
            return true
        }
    }

    async function onFilter() {
        removeAllActiveClasses("recentTradesList")
        await getFilteredValues()
    }

    async function onDeleteCount() {
        await deleteConf()
    }

    function deleteConf(shouldDelete = 0) {
        return new Promise(async (resolve, reject) => {
            try {
                let tokenId = getTokenId()
                /* global fetch */
                if (tokenId) {
                    setIsLoading(true)
                    let requestObj = getDeleteReqObj()

                    if (shouldDelete == 0) {
                        let deleteTrades = await fetch(`${helper.apiUrl}/account/trades/countdelete?` + new URLSearchParams(requestObj).toString() + `&token=${tokenId}`)
                        if (deleteTrades) {
                            deleteTrades = await deleteTrades.json();
                            if (!evaluateResponse(deleteTrades)) return
                            if (deleteTrades.error == 0) {
                                setDeleteTradesCount(deleteTrades?.trades?.length)
                                setDeleteConfModalShow(true)
                            } else {
                                alert(`Failed to get the list of trades, please re-login`)
                            }
                        }
                    } else {
                        let deletedTrades = await fetch(`${helper.apiUrl}/account/trades/countdelete?` + new URLSearchParams(requestObj).toString() + `&token=${tokenId}&delete=1`)
                        if (deletedTrades) {
                            deletedTrades = await deletedTrades.json();
                            if (!evaluateResponse(deletedTrades)) return
                            if (deletedTrades.error == 0) {
                                setDeleteConfModalShow(false)
                                defaultFilter()
                            } else {
                                alert(`Failed to remove the trades, please re-login`)
                            }
                        }
                    }
                    setIsLoading(false)
                }
                return resolve()
            } catch (error) {
                setIsLoading(false)
                console.log(`error while requeted to count delete trades `, error)
            }
        })
    }

    // return req object to delete trades
    function getDeleteReqObj() {
        let requestObj = {
            ticker: ticker,
            start: startDate,
            end: endDate
        }
        requestObj.start = requestObj.start ? `${requestObj.start.getFullYear()}-${requestObj.start.getMonth() + 1}-${requestObj.start.getDate()}` : ''
        requestObj.end = requestObj.end ? `${requestObj.end.getFullYear()}-${requestObj.end.getMonth() + 1}-${requestObj.end.getDate()}` : ''
        return requestObj
    }

    async function onClear() {
        removeAllActiveClasses("recentTradesList")
        document.querySelector(".allList").classList.add("active")
        setTicker('')
        setStartDate('')
        setEndDate('')

        let requestObj = {
            ticker: '',
            start: '',
            end: ''
        }
        localStorage.removeItem('filtertrades')
        await getFilteredValues(requestObj)
    }

    async function getLastXDays(days) {
        setTicker('')
        let requestObj = {
            ticker: '',
            start: '',
            end: ''
        }
        let newStartDate = new Date()
        newStartDate.setDate(newStartDate.getDate() - days)
        requestObj.start = newStartDate
        setStartDate(newStartDate)

        await getFilteredValues(requestObj)
    }

    function getFilteredValues(requestObj) {
        return new Promise(async (resolve, reject) => {
            try {
                setIsLoading(true)
                if (!requestObj) {
                    requestObj = {
                        ticker: ticker,
                        start: startDate,
                        end: endDate
                    }
                }
                // localStorage.setItem("filtertrades", JSON.stringify(requestObj))
                requestObj.start = requestObj.start ? `${requestObj.start.getFullYear()}-${requestObj.start.getMonth() + 1}-${requestObj.start.getDate()}` : ''
                requestObj.end = requestObj.end ? `${requestObj.end.getFullYear()}-${requestObj.end.getMonth() + 1}-${requestObj.end.getDate()}` : ''
                await getCalculatedValues(requestObj)
                setIsLoading(false)
            } catch (error) {
                setIsLoading(false)
                console.log(`error while filtering the value `, error)
            }
            return resolve()
        })
    }

    // Check in the local storage if the user PnL info is available
    // Verify the last update time, if not then update it again
    useEffect(() => {
        // let userPnLLocalStorage = localStorage.getItem("userPnL");
        // if (userPnLLocalStorage) {
        //     let requestObj = localStorage.getItem("filtertrades")
        //     if (requestObj) {
        //         requestObj = JSON.parse(requestObj)
        //         setTicker(requestObj.ticker)
        //         if (requestObj.start) setStartDate(new Date(requestObj.start))
        //         if (requestObj.end) setEndDate(new Date(requestObj.end))
        //     }

        //     userPnLLocalStorage = JSON.parse(userPnLLocalStorage)
        //     setUserPnL(userPnLLocalStorage)

        //     let tokenId = getTokenId()
        //     if (tokenId) {
        //         let lastUpdateTime = await fetch(`${helper.apiUrl}/account/trades/updatetime?token=${tokenId}`)
        //         if (lastUpdateTime) {
        //             lastUpdateTime = await lastUpdateTime.json();
        //             if (userPnLLocalStorage && userPnLLocalStorage.updatedAt && lastUpdateTime) {
        //                 lastUpdateTime = lastUpdateTime.updateTime
        //                 if (new Date(userPnLLocalStorage.updatedAt).getTime() !== new Date(lastUpdateTime).getTime()) {
        //                     onClear()
        //                 }
        //             }
        //         }
        //     }
        // }

        // onClear()
        getLastXDays(30)
    }, [])

    // After selecting a file, basic validation and update the message
    useEffect(() => {
        if (acceptedFiles.length > 10) {
            setDnDMessage(`Maximum 10 files allowed`)
        } else if (acceptedFiles.length > 0) {
            setDnDMessage(`${acceptedFiles.length} file(s) selected`)
        } else {
            setDnDMessage(`Upload statement(s)`)
        }
    }, [acceptedFiles])


    function updateDnDMessage() {
        if (acceptedFiles.length > 10) {
            setDnDMessage(`Maximum 10 files allowed`)
        } else if (acceptedFiles.length > 0) {
            setDnDMessage(`${acceptedFiles.length} file(s) selected`)
        } else {
            setDnDMessage(`Upload statement(s)`)
        }
    }

    // Drag'n'Drop functionality - set high index if the block is clicked (to keep it on top of the other blocks)
    function updateIndex(e) {
        let dragHandles = document.getElementsByClassName("dragHandle");
        for (let eachHandle of dragHandles) {
            if (eachHandle) {
                let el = eachHandle.closest(".react-draggable");
                if (el) {
                    el.style.zIndex = 1
                }
            }
        }
        let el = e.target.closest(".react-draggable");
        if (el) {
            el.style.zIndex = 90
        }
    }

    // create list of timezones
    function getListOfTz() {
        let listOfTz = []
        for (let i = 0; i < helper.tzStrings.length; i++) {
            listOfTz.push(
                <option key={i} value={helper.tzStrings[i].value}>{helper.tzStrings[i].label}</option>
            )
        }
        return listOfTz
    }

    // create list of timezones
    function getListOfBrokers() {
        let listOfBrokers = []
        for (let i = 0; i < BROKERS.length; i++) {
            listOfBrokers.push(
                <option key={i} value={BROKERS[i].value}>{BROKERS[i].label}</option>
            )
        }
        return listOfBrokers
    }
    const nodeRef = useRef(null);

    // Google login
    function handleLogin(response) {
        if (response.credential) {
            /* global localStorage */
            localStorage.setItem('loginData', response.credential)
            let userObj = helper.getUserInfo()
            globalStateDispatch({ type: "login", value: userObj })
            defaultFilter()
        }
    }

    function logoutUser() {
        /* global localStorage */
        localStorage.removeItem('loginData')
        localStorage.removeItem('userPnL')
        localStorage.removeItem('filtertrades')
        globalStateDispatch({ type: "login", value: null })
    }

    function getTokenId() {
        if (helper.getUserInfo()) {
            return localStorage.getItem('loginData')
        } else {
            /* global localStorage */
            console.log("Failed to verify google sign in, please sign in again")
            logoutUser()
            return null
        }
    }

    useEffect(() => {
        let googleInitInterval = setInterval(() => {
            let isGoogleInit = initGoogleSignin()
            if (isGoogleInit) {
                clearInterval(googleInitInterval)
            }
        }, 10)
    }, [])

    function initGoogleSignin() {
        /* global google */
        if (typeof google !== 'undefined' && google) {
            google.accounts.id.initialize({
                client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
                callback: handleLogin
            })

            google.accounts.id.renderButton(
                document.getElementById("googleSignIn"),
                { theme: "filled_blue", size: "large", shape: "circle" }
            )
            return true
        }
        return false
    }

    useEffect(() => {
        if (!helper.getUserInfo()) {
            if (typeof google !== 'undefined') {
                google.accounts.id.renderButton(
                    document.getElementById("googleSignIn"),
                    { theme: "filled_blue", size: "large", shape: "circle" }
                )
                setUserPnL({
                    closedTrades: [],
                    error: 0,
                    openTrades: [],
                    totalFees: 0,
                    totalPnL: 0
                })
            }
        }
    }, [globalState.loginData])

    const [deleteConfModalShow, setDeleteConfModalShow] = useState(false);
    const deleteConfModalClose = () => setDeleteConfModalShow(false)

    // recent days list
    function addActiveClass(className, e) {
        removeAllActiveClasses(className)
        e.target.classList.add("active")
    }
    function removeAllActiveClasses(className) {
        document.querySelectorAll(`.${className}`).forEach((el) => {
            el.classList.remove('active');
        });
    }

    function defaultFilter() {
        removeAllActiveClasses("recentTradesList")
        document.querySelector(".defaultRecentDaysList").classList.add("active")
        getLastXDays(30)
    }

    return (
        <div className="TradeJournal">
            <Header />

            {globalState.loginData ?
                <>
                    {/* Modal for delete confirmation */}
                    <Modal
                        show={deleteConfModalShow}
                        onHide={deleteConfModalClose}
                        backdrop="static"
                        keyboard={false}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>Delete Trades Confirmation</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {deleteTradesCount} transactions will be deleted.<br />
                            Would you like to delete all transactions?
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={deleteConfModalClose}>
                                Cancel
                            </Button>
                            <Button variant="danger" onClick={() => {
                                deleteConfModalClose()
                                deleteConf(1)
                            }}>Delete</Button>
                        </Modal.Footer>
                    </Modal>
                    <div className="container-fluid">
                        <div className="row" >
                            <div className="col-12 col-xl-6">
                                <TradeFilter
                                    ticker={ticker}
                                    setTicker={setTicker}
                                    startDate={startDate}
                                    setStartDate={setStartDate}
                                    endDate={endDate}
                                    setEndDate={setEndDate}
                                    onFilter={onFilter}
                                    onClear={onClear}
                                    onDeleteCount={onDeleteCount}
                                />
                            </div>
                            <div className="col-12 col-xl-6">
                                <div className="row">
                                    <div className="col-12 col-xl-5" >
                                        <div className="row">
                                            <div className="col-6 d-flex justify-content-center" style={{ marginTop: "10px" }}>
                                                <select
                                                    value={timeZone}
                                                    onChange={e => setTimeZone(e.target.value)}
                                                    className="form-input"
                                                    style={{ maxWidth: "350px", height: "35px", padding: "0 0.5rem", fontSize: "0.9rem", marginRight: "10px" }}>
                                                    {getListOfTz()}
                                                </select>
                                            </div>
                                            <div className="col-6 d-flex justify-content-center" style={{ marginTop: "10px" }}>
                                                <select
                                                    value={brokerName}
                                                    onChange={e => setTimeZone(e.target.value)}
                                                    className="form-input"
                                                    style={{ maxWidth: "350px", height: "35px", padding: "0 0.5rem", fontSize: "0.9rem" }}>
                                                    {getListOfBrokers()}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-xl-7" style={{ marginTop: "10px" }}>
                                        <div className="row">
                                            <div className="col-9 d-flex justify-content-center">
                                                <section className="container-fluid">
                                                    <div {...getRootProps({ className: 'dropzone' })}>
                                                        <input {...getInputProps()} />
                                                        <p className="m-0" style={{ fontSize: "0.9rem" }}>{DnDMessage}</p>
                                                    </div>
                                                </section>
                                            </div>
                                            <div className="col-3 d-flex justify-content-center">
                                                <button onClick={uploadStatments} className="header-button" style={{ background: "#4d7cff", fontSize: "0.9rem", padding: "2px 10px", border: "none", height: "30px" }}>Upload</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 d-flex justify-content-center" style={{ marginTop: "30px" }}>
                                <ListGroup horizontal>
                                    <ListGroup.Item style={{ fontSize: "12px", cursor: "pointer", padding: ".4rem 0.7rem" }} className="recentTradesList defaultRecentDaysList active" onClick={(e) => {
                                        addActiveClass("recentTradesList", e)
                                        getLastXDays(30)
                                    }}>30 days</ListGroup.Item>
                                    <ListGroup.Item style={{ fontSize: "12px", cursor: "pointer", padding: ".4rem 0.7rem" }} className="recentTradesList" onClick={(e) => {
                                        addActiveClass("recentTradesList", e)
                                        getLastXDays(60)
                                    }}>60 days</ListGroup.Item>
                                    <ListGroup.Item style={{ fontSize: "12px", cursor: "pointer", padding: ".4rem 0.7rem" }} className="recentTradesList" onClick={(e) => {
                                        addActiveClass("recentTradesList", e)
                                        getLastXDays(90)
                                    }}>90 days</ListGroup.Item>
                                    <ListGroup.Item style={{ fontSize: "12px", cursor: "pointer", padding: ".4rem 1rem" }} className="recentTradesList allList" onClick={(e) => {
                                        onClear()
                                    }}>All</ListGroup.Item>
                                </ListGroup>
                            </div>
                            <div className="col-12" style={{ marginTop: "10px", height: "20px" }}>
                                {isLoading && <div className="loader-demo-box">
                                    <div className="dot-opacity-loader">
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </div>
                                </div>}
                            </div>
                        </div>
                    </div>

                    {isCalculating ?
                        <div className='mt-4 d-flex flex-column align-items-center justify-content-center'>
                            <div>We're currently crunching the numbers for your stock statistics. </div>
                            <div>Thank you for your patience.</div>
                        </div>
                        :
                        <div className="container-fluid">
                            <div className="row">

                                <Draggable
                                    handle=".dragHandle"
                                    scale={1}
                                    defaultPosition={{ x: 0, y: 0 }}
                                    position={null}
                                    onStart={updateIndex}
                                    nodeRef={nodeRef}>
                                    <div className="col-xxl-4 col-xl-12 col-md-12"
                                        onClick={updateIndex}
                                        ref={nodeRef}>
                                        <OverView
                                            data={userTradesAnalysis}
                                        />
                                    </div>
                                </Draggable>


                                <Draggable
                                    handle=".dragHandle"
                                    scale={1}
                                    defaultPosition={{ x: 0, y: 0 }}
                                    position={null}
                                    onStart={updateIndex}
                                    nodeRef={nodeRef}>
                                    <div className="col-xxl-4 col-xl-6 col-md-12"
                                        onClick={updateIndex}
                                        ref={nodeRef}>
                                        <CLineChart
                                            data={userTradesAnalysis && userTradesAnalysis['pnlPerDay']}
                                        />
                                    </div>
                                </Draggable>


                                <Draggable
                                    handle=".dragHandle"
                                    scale={1}
                                    defaultPosition={{ x: 0, y: 0 }}
                                    position={null}
                                    onStart={updateIndex}
                                    nodeRef={nodeRef}>
                                    <div className="col-xxl-4 col-xl-6 col-md-12"
                                        onClick={updateIndex}
                                        ref={nodeRef}>
                                        <CBarChart
                                            data={userTradesAnalysis && userTradesAnalysis['pnlPerDay']}
                                        />
                                    </div>
                                </Draggable>

                                <Draggable
                                    handle=".dragHandle"
                                    scale={1}
                                    defaultPosition={{ x: 0, y: 0 }}
                                    position={null}
                                    onStart={updateIndex}
                                    nodeRef={nodeRef}>
                                    <div className="col-xxl-4 col-lg-6 col-md-12"
                                        onClick={updateIndex}
                                        ref={nodeRef}>
                                        <CBarChartByDay
                                            data={userTradesAnalysis && userTradesAnalysis['pnlPerWeekDay']}
                                        />
                                    </div>
                                </Draggable>

                                <Draggable
                                    handle=".dragHandle"
                                    scale={1}
                                    defaultPosition={{ x: 0, y: 0 }}
                                    position={null}
                                    onStart={updateIndex}
                                    nodeRef={nodeRef}>
                                    <div className="col-xxl-4 col-lg-6 col-md-12"
                                        onClick={updateIndex}
                                        style={{ height: "300px" }}
                                        ref={nodeRef}>
                                        <CWinLossStats
                                            data={userTradesAnalysis}
                                        />
                                    </div>
                                </Draggable>

                                <Draggable
                                    handle=".dragHandle"
                                    scale={1}
                                    defaultPosition={{ x: 0, y: 0 }}
                                    position={null}
                                    onStart={updateIndex}
                                    nodeRef={nodeRef}>
                                    <div className="col-xxl-4 col-lg-6 col-md-12"
                                        onClick={updateIndex}
                                        ref={nodeRef}>
                                        <CTopProfitLoss
                                            data={userTradesAnalysis}
                                        />
                                    </div>
                                </Draggable>

                                <Draggable
                                    handle=".dragHandle"
                                    scale={1}
                                    defaultPosition={{ x: 0, y: 0 }}
                                    position={null}
                                    onStart={updateIndex}
                                    nodeRef={nodeRef}>
                                    <div className="col-xxl-4 col-lg-6 col-md-12"
                                        onClick={updateIndex}
                                        ref={nodeRef}>
                                        <CTimeStats
                                            data={userTradesAnalysis}
                                        />
                                    </div>
                                </Draggable>
                            </div>
                        </div>
                    }
                </>

                :

                <div className="container-fluid" style={{ marginTop: "200px" }}>
                    <div className="row" >
                        <div className="col-12 d-flex justify-content-center">
                            <div id="googleSignIn"></div>
                        </div>
                    </div>
                </div>
            }

        </div>
    )
}

export default Trades
