let globalScreenerTableSetting = {
    "price": {
        type: "minMax",
        min: "1",
        max: "4000",
        minValue: "0",
        maxValue: "4000",
        step: "1"
    },
    "float": {
        type: "minMax",
        min: "0",
        max: "",
        minValue: "",
        maxValue: "",
        step: "10"
    },
    "volume": {
        type: "minMax",
        min: "0",
        max: "",
        minValue: "",
        maxValue: "",
        step: "1000"
    },
    "spread": {
        type: "number",
        value: "15",
        min: "0",
        max: "3",
        step: "0.1"
    },
    "breakHigh": {
        type: "radio",
        value: false
    }
}

let gapperTableSetting = JSON.parse(JSON.stringify(globalScreenerTableSetting))
delete gapperTableSetting["spread"]
delete gapperTableSetting["breakHigh"]

let momo5m3cTableSetting = JSON.parse(JSON.stringify(globalScreenerTableSetting))
momo5m3cTableSetting = { ...momo5m3cTableSetting, float: { ...momo5m3cTableSetting.float, max: 200 } }

export default function getTableSettings(tableName) {
    if (tableName === "gapperHigh") {
        return gapperTableSetting
    } else if (tableName === "momo5m3c") {
        return momo5m3cTableSetting
    }
}