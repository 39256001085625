import React from 'react'
import middlecenter from '../../public/assets/images/middle/middle-center-bg.webp'
import pnlTrendline from '../../public/assets/images/middle/PnL-Trendline.webp'
import pnlDaily from '../../public/assets/images/middle/PnL-daily.webp'
import trades from '../../public/assets/images/middle/trades.webp'
import pnlAnalysis from '../../public/assets/images/middle/PnL-analysis.webp'

export default function Top() {
    return (
        <section style={{ "paddingTop": "60px" }}>
            <div className="container-xxl" style={{ position: 'relative' }}>
                <div className="" data-background={middlecenter} style={{ padding: "50px, 0px", width: "100%" }}>
                    <div className="d-flex flex-column" >
                        <div className="row">
                            <div data-aos="fade-right" data-aos-duration="500" className="col-xxl-6 col-xl-12">
                                <div className="d-flex justify-content-center">
                                    <img className="img-fluid" style={{ borderRadius: "30px" }} alt="trade analysis" src={trades} />
                                </div>
                            </div>
                            <div data-aos="fade-left" data-aos-duration="700" className="col-xxl-6 col-xl-12 pt-4">
                                <div className="d-flex flex-column justify-content-around align-items-center">
                                    <h3 className="mb-4" style={{ color: "#ff8a00" }}>Discover Powerful Analysis Tool</h3>
                                    <h5 className="mb-4 text-center" style={{ textAlignLast: "center" }}>Take Control of Your Trades: Upload and analyze your data, identify optimal patterns tailored to your strategy, and elevate your trading decisions with precision</h5>
                                    <a className="mb-4 scanner-button" href="/trades">Start using for free</a>
                                </div>
                            </div>
                        </div>
                        <div className="row" style={{ marginTop: "120px" }}>
                            <div data-aos="fade-right" data-aos-duration="700" className="col-xl-4 col-sm-12">
                                <div className="d-flex justify-content-center align-items-center">
                                    <img className="img-fluid pnl-trendline-img" style={{ marginBottom: "100px", borderRadius: "30px" }} alt="profit and loss trendline" src={pnlTrendline} />
                                </div>
                            </div>

                            <div data-aos="fade-up" data-aos-duration="700" className="col-xl-4 col-sm-12">
                                <div className="d-flex justify-content-center align-items-center">
                                    <img className="img-fluid" style={{ marginBottom: "100px", borderRadius: "30px" }} alt="profit and loss analysis" src={pnlAnalysis} />
                                </div>
                            </div>

                            <div data-aos="fade-left" data-aos-duration="700" className="col-xl-4 col-sm-12">
                                <div className="d-flex justify-content-center align-items-center">
                                    <img className="img-fluid pnl-daily-img" style={{ marginBottom: "100px", borderRadius: "30px" }} alt="profit and loss daily chart" src={pnlDaily} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}