import { useState, useEffect, useRef } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog, faQuestionCircle, faGripHorizontal, faExpand, faCompress } from '@fortawesome/free-solid-svg-icons'
import { createChart, CrosshairMode } from 'lightweight-charts'
import $ from 'jquery'

import useTradingViewChart from '../controllers/useTradingViewChart'

export default function TradingViewChart({ title, ticker, idName, height = 475 }) {
    const nodeRef = useRef(null);

    useEffect(() => {
        $(`.expandChart`).click((e) => {
            $(e.target).parents(`.react-draggable`).addClass(`panel-fullscreen`)
            $(e.target).parents(`.react-draggable`).find(`.card`).css("height", "100%")

            $(e.target).parents('.card').find(`.expandChart`).hide()
            $(e.target).parents('.card').find(`.dragHandle`).hide()
            $(e.target).parents('.card').find(`.compressChart`).show()
        })

        $(`.compressChart`).click((e) => {
            $(e.target).parents(`.react-draggable`).removeClass(`panel-fullscreen`)
            $(e.target).parents(`.react-draggable`).find(`.card`).css("height", "inherit")

            $(e.target).parents('.card').find(`.compressChart`).hide()
            $(e.target).parents('.card').find(`.dragHandle`).show()
            $(e.target).parents('.card').find(`.expandChart`).show()
        })
    }, [])

    useEffect(() => {
        // const chart = createChart("tradeViewChart", {
        //     width: 800,
        //     height: 400
        // });
        // console.log(chart)

        // const candlestickSeries = chart.addCandlestickSeries();
        // candlestickSeries.setData([
        //     { time: '2018-12-22', open: 75.16, high: 82.84, low: 36.16, close: 45.72 },
        //     { time: '2018-12-23', open: 45.12, high: 53.90, low: 45.12, close: 48.09 },
        //     { time: '2018-12-24', open: 60.71, high: 60.71, low: 53.39, close: 59.29 },
        //     { time: '2018-12-25', open: 68.26, high: 68.26, low: 59.04, close: 60.50 },
        //     { time: '2018-12-26', open: 67.71, high: 105.85, low: 66.67, close: 91.04 },
        //     { time: '2018-12-27', open: 91.04, high: 121.40, low: 82.70, close: 111.40 },
        //     { time: '2018-12-28', open: 111.51, high: 142.83, low: 103.34, close: 131.25 },
        //     { time: '2018-12-29', open: 131.33, high: 151.17, low: 77.68, close: 96.43 },
        //     { time: '2018-12-30', open: 106.33, high: 110.20, low: 90.39, close: 98.10 },
        //     { time: '2018-12-31', open: 109.87, high: 114.69, low: 85.66, close: 111.26 },
        // ]);
    }, [])

    const [cardKey, setCardKey] = useState(0)
    useEffect(() => {
        // add ticker change effect on quote and chart boxes
        setCardKey(cardKey + 1)
    }, [ticker])



    const bodyStyle = {
        overflow: "auto",
        height: `${height}px`
    }
    return (
        <div className="card"
            ref={nodeRef}
            style={{
                overflow: "auto",
                resize: "both",
            }}>
            <div className="card-header">
                <div className="container-fluid d-inline-flex justify-content-between">
                    <div className="align-self-start">
                        <div className="box-shadow" key={cardKey}>
                            {title} - {ticker.toUpperCase()}
                        </div>
                    </div>
                    <div>
                        <FontAwesomeIcon className="dragHandle" variant="secondary" icon={faGripHorizontal} style={{ fontSize: "1.1rem" }} />
                    </div>
                    <div>
                        <div className="expandChart" style={{ cursor: "pointer" }}> <FontAwesomeIcon icon={faExpand} /></div>
                        <div className="compressChart" style={{ cursor: "pointer", display: "none" }}> <FontAwesomeIcon icon={faCompress} /> </div>
                    </div>
                </div>
            </div>
            <div className="card-body"
                style={bodyStyle}>
                <div id={idName}></div>
            </div>
        </div >
    )
}