import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  BarChart,
  Bar,
  Brush,
  ReferenceLine,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from 'recharts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGripHorizontal } from '@fortawesome/free-solid-svg-icons'


const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index, payload }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" style={{ fontSize: "12px", fontWeight: "bold" }} textAnchor={payload.unique === "s" ? "start" : "middle"} dominantBaseline="central">
      {payload.name} {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};



export default function CWinLossStats({ data }) {
  if (!data || (!data.totalWinTrade && !data.totalLossTrade && !data.totalScratchTrade)) return (<></>)

  const CustomBarTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="recharts-custom-tooltip d-flex flex-column">
          <div className="recharts-label">{`Profit/Loss : $${(payload[0].value).toFixed(2)}`}</div>
          <div className="recharts-label">{`Trades : ${payload[0].payload.trades}`}</div>
          <div className="recharts-label">{`Percentage : ${((payload[0].payload.trades / data.totalTrades) * 100).toFixed(0)}%`}</div>
          <div className="recharts-label">{`Per trade P/L : $${(payload[0].value / payload[0].payload.trades).toFixed(2)}`}</div>
        </div>
      );
    }
    return null;
  }

  const CustomPieTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="recharts-custom-tooltip d-flex flex-column">
          <div className="recharts-label">{`${payload[0].payload['name']} rate : ${((payload[0].value / data.totalTrades) * 100).toFixed(0)}%`}</div>
          <div className="recharts-label">{`Total trades : ${payload[0].value}`}</div>
        </div>
      );
    }
    return null;
  }

  const pieData = [
    { name: 'Win', value: data.totalWinTrade, unique: "w" },
    { name: 'Loss', value: data.totalLossTrade, unique: "l" },
    { name: 'Scratch', value: data.totalScratchTrade, unique: "s" }
  ]

  const barData = [
    { name: "Win", pnl: data.totalWinAmount, trades: data.totalWinTrade },
    { name: "Loss", pnl: data.totalLossAmount, trades: data.totalLossTrade }
  ]
  return (
    <div className="card"
      style={{
        overflow: "auto",
        resize: "both",
        marginTop: "30px"
      }}>
      <div className="card-header">
        <div className="container d-inline-flex justify-content-between">
          <div className="align-self-start">
            Profit/Loss Percentage
          </div>
          <FontAwesomeIcon className="dragHandle" variant="secondary" icon={faGripHorizontal} style={{ fontSize: "1.1rem", margin: "5px 10px 0px" }} />
          <div></div>
        </div>
      </div>
      <div className="card-body"
        style={{
          overflow: "auto",
          height: "435px"
        }}>
        <div className="row" style={{ height: "100%", width: "100%" }}>
          <div className="col-md-6 d-flex flex-column justify-content-center">
            <ResponsiveContainer width="100%" height={300}>
              <PieChart>
                <Pie
                  data={pieData}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  label={renderCustomizedLabel}
                  outerRadius="80%"
                  fill="#8884d8"
                  dataKey="value"
                >
                  {pieData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={pieData[index].unique === "w" ? "#0acf97" : pieData[index].unique === "l" ? "#fc5661" : "#f5a623"} />
                  ))}
                </Pie>
                <Tooltip content={<CustomPieTooltip />} cursor={false} />
              </PieChart>
            </ResponsiveContainer>
          </div>

          <div className="col-md-6 d-flex flex-column justify-content-center">
            <ResponsiveContainer width="100%" height={250}>
              <BarChart
                data={barData}
                layout="vertical"
                barGap={0}
                barCategoryGap={0}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5
                }}
              >
                <CartesianGrid strokeOpacity={0.2} strokeDasharray="3 3" />
                <XAxis dataKey="pnl" stroke="#c8c6c6" type="number" />
                <YAxis dataKey="name" stroke="#c8c6c6" tick={{ fontSize: 12 }} width={10} type="category" />
                <Tooltip content={<CustomBarTooltip />} cursor={false} />
                <Legend verticalAlign="top" wrapperStyle={{ lineHeight: "40px" }} />
                <ReferenceLine y={0} stroke="#000" />
                <Bar
                  animationDuration={2000}
                  dataKey="pnl"
                  barSize={50}
                  name="Win/Loss trades"
                  fill="#8884d8"
                >
                  {barData.map((entry, index) => (
                    <Cell key={index} fill={barData[index].name === "Win" ? "#0acf97" : "#fc5661"} />
                  ))}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    </div>
  )
}
